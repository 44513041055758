import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslate } from './TranslationText';
const TabStyle = {
    display: "block",
    marginRight: 0,
    marginLeft: 0,
    padding: "10px 20px",
    textAlign: "center",
    background: "rgba(0,0,0,0.08)",
    cursor: "pointer",
    color: "#000",
    transition: "all 300ms",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    fontSize: 15,
};
const BaseURL = process.env.REACT_APP_BASE_URL;

export default function JudgeList() {
    const { currentLanguage } = useSelector((state) => state.studBookSlice);

    const {
        showJudgesData,
        showClassRankListData,
    } = useSelector((state) => state.showsSlice);
    const { translate } = useTranslate();

    const [judgesHovered, setJudgesHovered] = useState(false);
    const [judgesActive, setJudgesActive] = useState(true);

    const [dcHovered, setDcHovered] = useState(false);
    const [dcActive, setDcActive] = useState(false);

    const [ringMasterHovered, setRingMasterHovered] = useState(false);
    const [ringMasterActive, setRingMasterActive] = useState(false);
    const [selectedSearchType, setSelectedSearchType] = useState("2");

    const handleSelectedSearchType = (e) => {
        setSelectedSearchType(e);
    };

    return <div className="col-lg-4 col-md-4 col-sm-12 " style={{ paddingLeft: "1em" }}>
        <div className="row m0">
            {
                // judges buttons
            }
            {showJudgesData.filter((a) => a.role === "2").length > 0 && (
                <div
                    className="col-lg-4  p0"
                    style={{
                        ...TabStyle,
                        background: judgesHovered || judgesActive
                            ? "#E44D52"
                            : TabStyle.background,
                        color: judgesHovered || judgesActive ? "#fff" : TabStyle.color,
                        paddingTop: 6
                    }}
                    onMouseOver={() => setJudgesHovered(true)}
                    onMouseOut={() => setJudgesHovered(false)}
                    onClick={() => {
                        setJudgesActive(true);
                        setDcActive(false);
                        setRingMasterActive(false);
                        handleSelectedSearchType("2");
                    }}
                >
                    <span
                        
                    >
                        {translate("web.judges", "Judges")}
                    </span>
                </div>
            )}
            {showJudgesData.filter((a) => a.role === "1").length > 0 && (
                <div
                    className="col-lg-4  p0responsive"
                >
                    <span
                        style={{
                            ...TabStyle,
                            background: dcHovered || dcActive ? "#E44D52" : TabStyle.background,
                            color: dcHovered || dcActive ? "#fff" : TabStyle.color,

                        }}
                        onMouseOver={() => setDcHovered(true)}
                        onMouseOut={() => setDcHovered(false)}
                        onClick={() => {
                            setJudgesActive(false);
                            setRingMasterActive(false);
                            setDcActive(true);
                            handleSelectedSearchType("1");
                        }}
                    >
                        {translate("web.dc", "DC")}
                    </span>
                </div>
            )}

            {showJudgesData.filter((a) => a.role === "0").length > 0 && (
                <div
                    className="col-lg-4  p0 "
                    style={{
                        ...TabStyle,
                        background: ringMasterHovered || ringMasterActive
                            ? "#E44D52"
                            : TabStyle.background,
                        color: ringMasterHovered || ringMasterActive
                            ? "#fff"
                            : TabStyle.color,
                            paddingTop: 6
                    }}
                    onMouseOver={() => setRingMasterHovered(true)}
                    onMouseOut={() => setRingMasterHovered(false)}
                    onClick={() => {
                        setJudgesActive(false);
                        setDcActive(false);
                        setRingMasterActive(true);
                        handleSelectedSearchType("0");
                    }}
                >
                    <span
                        
                    >
                        {translate("web.ringMaster", "Ring Master")}
                    </span>
                </div>
            )}

            {
                // judges data
            }
            {showJudgesData.length > 0 && (
                <div
                    className="col-lg-12 col-md-12 col-sm-12 judgesList"
                    style={{
                        minHeight: 250,
                        padding: 0,
                        borderBottomLeftRadius:5,
                        borderBottomRightRadius:5,
                        background: "rgba(0, 0, 0, 0.08)",
                        overflowX: "hidden",
                        maxHeight: !!showClassRankListData && showClassRankListData.length > 0
                            ? 356
                            : 660,
                    }}
                >
                    {!!showJudgesData &&
                        showJudgesData.filter((a) => a.role === selectedSearchType)
                            .length > 0 ? (
                        showJudgesData
                            .filter((a) => a.role === selectedSearchType)
                            .map((item, i) => (
                                <div key={i} className="JudgesTableRow">
                                    {selectedSearchType === "2" && (
                                        <div className="showDetailsJudesLetter ">
                                            {item.letter}
                                        </div>
                                    )}

                                    <img
                                        className="countryFlageImg "
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = `${BaseURL}/assets/img/whitebgimg.png`;
                                        }}
                                        src={item.countryFlag}
                                        alt={item.countryName} />

                                    <div className="showDetailsJudesName ">
                                        {item.name}
                                    </div>
                                </div>
                            ))
                    ) : (
                        <div
                            className="row text-danger text-center "
                            style={{ position: "relative", top: 98 }}
                        >
                            {translate("web.nodataFound", "No Data Found !")}
                        </div>
                    )}
                </div>
            )}

            {
                //Current class rank
            }
            {!!showClassRankListData && showClassRankListData.length > 0 && (
                <div
                    className="col-lg-12 col-md-12 col-sm-12 mt-1"
                    style={{
                        marginTop: 50,
                        minHeight: 367,
                        maxHeight: 367,
                        overflowX: "hidden",
                    }}
                >
                    <h2 className='resblock'>
                        {translate("web.currentClassRank", "Current class rank")}
                    </h2>
                    <table   
                        className="table tablenominwidth table-striped table-bordered rtl horseResultTable"
                        style={{ direction: currentLanguage == "ar" ? "rtl" : "ltr"  , width:'100%' , minWidth:0}}
                    >
                        <thead className='text-center'
                            style={{
                                backgroundColor: "#c89c66",
                                color: "white",
                            }}
                        >
                            <th>
                                {translate("web.rank", "Rank")}
                            </th>
                            <th>{translate("web.horseName", "Horse Name")}</th>
                            <th>{translate("web.totalPoints", "Total Points")}</th>
                        </thead>
                        <tbody className='text-center'>
                            {showClassRankListData.map((item, i) => (
                                <tr key={i}>
                                    <td className='bold'>{item.rank}</td>
                                    <td className='bold'>
                                        {item.horseNumber} {item.name}
                                    </td>
                                    <td className='bold' >{item.totalPoint}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    </div>;
}
