import axios from "../../axios/axios";

export const FetchCreateOwnerShipTransferServicesRequestsAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    debugger;
    formData.append("id", param.id);
    formData.append("serviceDefinitionId", param.serviceDefinitionId);
    formData.append("employeeId", param.employeeId);
    formData.append("date", param.date);
    formData.append("status", param.status);
    formData.append("requestDetails.horseId", param.requestDetails.horseId);
    formData.append("requestDetails.ownerId", param.requestDetails.ownerId);
    formData.append(
      "requestDetails.newOwnerId",
      param.requestDetails.newOwnerId
    );
    formData.append(
      "requestDetails.attachmentFile",
      param.requestDetails.attachmentFile
    );
    formData.append(
      "requestDetails.documentTypeId",
      param.requestDetails.documentTypeId
    );
    formData.append("requestDetails.createdBy", param.requestDetails.createdBy);

    const response = await axios.post(
      `/ServicesRequests/CreateOwnerShipTransferServicesRequests`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetAllServicesRequestsAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetAllServicesRequests?language=${param.language}&userId=${param.userId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetServicesRequestByIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetServicesRequestById?id=${param.id}&language=${param.language}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchDeleteOwnerShipTransferServicesRequestsAPI = async (
  param
) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/DeleteOwnerShipTransferServicesRequests?id=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetAllServicesRequestsOwnerToApproveAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetAllServicesRequestsOwnerToApprove?language=${param.language}&userId=${param.userId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateOwnerShipTransferServicesRequestsAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("serviceDefinitionId", param.serviceDefinitionId);
    formData.append("employeeId", param.employeeId);
    formData.append("date", param.date);
    formData.append("status", param.status);
    formData.append("requestDetails.horseId", param.requestDetails.horseId);
    formData.append("requestDetails.ownerId", param.requestDetails.ownerId);
    formData.append(
      "requestDetails.newOwnerId",
      param.requestDetails.newOwnerId
    );
    formData.append(
      "requestDetails.attachmentFile",
      param.requestDetails.attachmentFile
    );
    formData.append(
      "requestDetails.documentTypeId",
      param.requestDetails.documentTypeId
    );

    const response = await axios.post(
      `/ServicesRequests/UpdateOwnerShipTransferServicesRequests`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchApproveRejectRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/ServicesRequests/ApproveRejectRequest`, {
      id: param.id,
      employeeId: param.employeeId,
      status: param.status,
      comment: param.comment,
      address: param.address,
      mobile: param.mobile,
      receiveType: param.receiveType,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetServiceDefinitionByIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetServiceDefinitionById?id=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetFileAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetFile?filePath=${param.filePath}&fileType=${param.Type}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetFilebase64API = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetFilebase64?filePath=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetDocumentTypesByServiceIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetDocumentTypesByServiceId?id=${param.id}&lang=${param.language}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
