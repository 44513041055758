import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchGetOwnersListthunk,
  FetchOwnerHorsesDropDownthunk,
} from "../Redux/OwnerProfile/OwnerProfileAction";
import {
  FetchFilterOwnersOrUsersthunk,
  FetchStudBookCountriesthunk,
  FetchStudBookOwnerDetailsthunk,
} from "../Redux/StudBook/StudBookAction";
import {
  FetchApproveRejectRequestthunk,
  FetchCreateOwnerShipTransferServicesRequeststhunk,
  FetchGetDocumentTypesByServiceIdthunk,
  FetchGetFilebase64thunk,
  FetchGetFilethunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchUpdateOwnerShipTransferServicesRequeststhunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import axiosInstance from "../axios/axios";
import UploadFile from "../components/UploadFile";
import { formatDate } from "../helper/formatDate";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import Popup from "reactjs-popup";
import { base64ToBlob } from "../helper/base64ToBlob";
import { normalDateFormat } from "../helper/normalDateFormat";
import {
  FetchCountriesthunk,
  FetchHorseColorsthunk,
} from "../Redux/LookUp/LookUpAction";

export default function RequestCoveringCertificate() {
  //env
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { ownerHorsesDropDownData } = useSelector(
    (state) => state.ownerProfileSlice
  );
  const { countriesData, horseColorsData } = useSelector(
    (state) => state.lookUpSlice
  );

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    getFilebase64Data,
    getDocumentTypesByServiceIdData,
  } = useSelector((state) => state.servicesRequestsSlice);

  const { showEntryLoading } = useSelector((state) => state.showEntrySlice);
  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  //dispatch
  const dispatch = useDispatch();

  //translate
  const { translate } = useTranslate();

  //navigate
  const navigate = useNavigate();

  //Location
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    isStallionRegistered: true,
    isMareRegistered: true,
    notStallionOwner: false,
    horseStallionReg: "",
    horseStallionRegDrop: {
      label: translate("web.horse", "Horse"),
      value: null,
    },
    regNoStallionReg: "",
    horseNameStallionReg: "",
    originStallionReg: "",
    breederStallionReg: "",
    genderStallionReg: "",
    colorStallionReg: "",
    birthDateStallionReg: "",
    sireStallionReg: "",
    damStallionReg: "",
    ownerNameStallionReg: "",
    ownerEmailStallionReg: "",
    ownerMobileStallionReg: "",

    horseNameEnStallionUnReg: "",
    horseNameArStallionUnReg: "",
    currentRegNoStallionUnReg: "",
    damEnStallionUnReg: "",
    damArStallionUnReg: "",
    sireEnStallionUnReg: "",
    sireArStallionUnReg: "",
    colorStallionUnReg: "",
    colorStallionUnRegDrop: {
      label: translate("web.color", "Color"),
      value: null,
    },
    ownerNameEnStallionUnReg: "",
    ownerNameArStallionUnReg: "",
    originStallionUnReg: "",
    originStallionUnRegDrop: {
      label: translate("web.chooseOrigin", "choose Origin"),
      value: null,
    },
    uelnStallionUnReg: "",
    genderStallionUnReg: "",
    genderStallionUnRegDrop: {
      label: translate("web.gender", "Gender"),
      value: null,
    },
    birthDateStallionUnReg: "",
    breederNameEnStallionUnReg: "",
    breederNameArStallionUnReg: "",
    horseAddressStallionUnReg: "",
    horseCountryStallionUnReg: "",
    horseCountryStallionUnRegDrop: {
      label: translate("web.horseCountry", "Horse Country"),
      value: null,
    },

    breedingHappendAbroadAttachmentFile: null,
    stallionOwnerSignedPermitAttachmentFile: null,
    embryoIsImportedAttachmentFile: null,
    importedSemenCertificateAttachmentFile: null,

    horse: "",
    horseDrop: {
      label: translate("web.horse", "Horse"),
      value: "",
    },
    origin: "",
    regNo: "",
    horseName: "",
    gender: "",
    color: "",
    birthDate: "",
    sire: "",
    dam: "",
    breeder: "",
    owner: "",
    ownerDrop: {
      label: translate("web.owner", "owner"),
      value: "",
    },
    ownerName: "",
    ownerRegNo: "",
    country: "",
    city: "",
    area: "",
    phone: "",
    email: "",
    idNumber: "",
    employeeId: "",
    buyerComment: "",
    eventName: "",
    attachmentFile: null,
    comment: "",
    referenceNumber: "",
    address: "",
    mobile: "",
    filePath: "",
    fileType: "",
    fileName: "",
    documentType: "",
    documentTypeDrop: {
      label: translate("web.type", "Type"),
      value: "",
    },
    requestsType: "",
  });

  const [birthDate, setBirthdate] = useState(null);

  const [
    breedingHappendAbroadAttachmentFile,
    setBreedingHappendAbroadAttachmentFile,
  ] = useState(null);
  const [
    stallionOwnerSignedPermitAttachmentFile,
    setStallionOwnerSignedPermitAttachmentFile,
  ] = useState(null);
  const [embryoIsImportedAttachmentFile, setEmbryoIsImportedAttachmentFile] =
    useState(null);
  const [
    importedSemenCertificateAttachmentFile,
    setImportedSemenCertificateAttachmentFile,
  ] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [ownerListLoading, setOwnerListLoading] = useState(false);
  const _status = useRef("");
  const statusRequest = useRef("");
  const checkAnotherOwner = useRef(true);
  const [inputValue, setInputValue] = useState("");
  const [timeoutId, setTimeoutId] = useState(0);
  const [uploadAttachmentFile, setUploadAttachmentFile] = useState(null);
  const [stallionChecker, setStallionChecker] = useState(true);
  const [mareChecker, setMareChecker] = useState(true);
  const [checkedItems, setCheckedItems] = useState({
    //option1: false,
    option2: false,
    option3: false,
  });
  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
        " - " +
        userProfile[`fullName${currentLanguage == "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  //function
  const handleFormChange = (e, input) => {
    if (input === "horseStallion") {
      let horse = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      setFormModel({
        ...formModel,
        horseStallionRegDrop: e,
        horseStallionReg: e.value,
        regNoStallionReg: horse[0].registerNumber,
        horseNameStallionReg: horse[0].name,
        genderStallionReg: horse[0].gender,
        colorStallionReg: horse[0].color,
        birthDateStallionReg: horse[0].birthDate,
        sireStallionReg: horse[0].sire,
        damStallionReg: horse[0].dam,
        originStallionReg: horse[0].origin,
        breederStallionReg: horse[0].breeder,
      });
    } else if (input === "owner") {
      if (e?.value != null && e?.value != undefined) {
        setFormModel({
          ...formModel,
          ownerDrop: e,
          owner: e.value,
          email: e.details.email,
          phone: e.details.phone,
          idNumber: e.details.idNumber,
        });
      }
    } else if (input === "documentType") {
      setFormModel({
        ...formModel,
        documentTypeDrop: e,
        documentType: e.value,
      });
    } else if (input === "stallionType") {
      setFormModel({
        ...formModel,
        isStallionRegistered: e.target.value === "Registered" ? true : false,
      });
    } else if (input === "mareType") {
      setFormModel({
        ...formModel,
        isMareRegistered: e.target.value === "Registered" ? true : false,
      });
    } else if (input === "notStallionOwner") {
      setFormModel({
        ...formModel,
        notStallionOwner: e.target.checked,
      });
    } else if (!!input && input === "birthDate") {
      setBirthdate(e);

      setFormModel({
        ...formModel,
        birthDateStallionUnReg: e,
      });
    } else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handelFormSubmit = (status) => {
    if (
      (action == null || action === "edit" || action === "approve") &&
      formModel.attachmentFile != null &&
      (formModel.documentType === "" || formModel.documentType === null)
    ) {
      toast.error(
        translate("web.pleaseSelectDocumentType", "Please Select Document Type")
      );

      return false;
    }
    if (
      formModel.horse != "" &&
      formModel.owner != "" &&
      ((Object.values(checkedItems).every((value) => value === true) &&
        formModel.eventName === "") ||
        formModel.eventName !== "")
    ) {
      let params = {
        id: id,
        serviceDefinitionId: serviceId,
        // employeeId:
        //   formModel.eventName == "SentBack"
        //     ? formModel.employeeId
        //     : localStorage.getItem("EAHSUserID"),
        employeeId: localStorage.getItem("EAHSUserID"),
        date: new Date().toLocaleDateString(),
        status: formModel.eventName == "SentBack" ? "PendingReview" : status,
        //status: status == "BuyerApproved" ? "WatingBuyerApproval" : status,
        requestDetails: {
          horseId: formModel.horse,
          // ownerId:
          //   formModel.eventName == "SentBack"
          //     ? formModel.employeeId
          //     : localStorage.getItem("EAHSUserID"),
          ownerId:
            formModel.eventName == "SentBack"
              ? formModel.employeeId
              : getUserID(),
          newOwnerId: formModel.owner,
          attachmentFile: formModel.attachmentFile,
          documentTypeId: formModel.documentType,
          createdBy: localStorage.getItem("EAHSUserID"),
        },
      };

      if (action == null) {
        //statusRequest.current = "new";
        dispatch(FetchCreateOwnerShipTransferServicesRequeststhunk(params));
      } else if (action === "edit" || formModel.eventName == "SentBack") {
        //statusRequest.current = "edit";
        dispatch(FetchUpdateOwnerShipTransferServicesRequeststhunk(params));
      }
    } else {
      if (
        !Object.values(checkedItems).every((value) => value === true) &&
        formModel.eventName === ""
      ) {
        toast.error(
          translate("web.pleaseCheckAllNotes", "Please Check All Notes")
        );

        return false;
      }
      toast.error(
        translate("web.completeMissingData", "Complete The Missing Data")
      );
    }
  };

  const handelApproveRejectRequest = (status) => {
    _status.current = status;

    if (
      !formModel.isRegistration &&
      (formModel.address == "" ||
        formModel.address == null ||
        formModel.mobile == "" ||
        formModel.mobile == null)
    ) {
      toast.error(
        translate(
          "web.Pleaseenteryouraddressandmobilenumber.",
          "Please enter your address and mobile number."
        )
      );

      return false;
    }

    let params = {
      id: id,
      // employeeId: formModel.employeeId,
      employeeId: getUserID(),
      status: status,
      comment: formModel.buyerComment,
      address: formModel.address,
      mobile: formModel.mobile,
      receiveType: formModel.isRegistration ? 1 : 2,
    };

    dispatch(FetchApproveRejectRequestthunk(params));
  };

  const handleUploadBreedingHappendAbroadChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          breedingHappendAbroadAttachmentFile: e.target.files[0],
        });

        setBreedingHappendAbroadAttachmentFile({
          file: URL.createObjectURL(e.target.files[0]),
          name: e.target.files[0].name,
        });
      }
    }
  };

  const handleUploadStallionOwnerSignedPermitChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          breedingStallionOwnerSignedPermitAttachmentFile: e.target.files[0],
        });

        setStallionOwnerSignedPermitAttachmentFile({
          file: URL.createObjectURL(e.target.files[0]),
          name: e.target.files[0].name,
        });
      }
    }
  };

  const handleUploadEmbryoIsImportedChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          embryoIsImportedAttachmentFile: e.target.files[0],
        });

        setEmbryoIsImportedAttachmentFile({
          file: URL.createObjectURL(e.target.files[0]),
          name: e.target.files[0].name,
        });
      }
    }
  };

  const handleUploadImportedSemenCertificateChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          importedSemenCertificateAttachmentFile: e.target.files[0],
        });

        setImportedSemenCertificateAttachmentFile({
          file: URL.createObjectURL(e.target.files[0]),
          name: e.target.files[0].name,
        });
      }
    }
  };

  const handleUploadServiceRequestDocumentChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          attachmentFile: e.target.files[0],
        });

        setUploadAttachmentFile({
          file: URL.createObjectURL(e.target.files[0]),
          name: e.target.files[0].name,
        });
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    setProfile(element);

    setFormModel({
      ...formModel,
      horse: "",
      horseDrop: {
        label: translate("web.horse", "Horse"),
        value: "",
      },
      gender: "",
      color: "",
      birthDate: "",
      sire: "",
      dam: "",
      origin: "",
      breeder: "",
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  const closeModal = () => setOpenPopup(false);

  const subAccountId = localStorage.getItem("currentSubAccountId");

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName == "OwnerShipTransfer" || x.permissionName == "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      userProfile.accountType != "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    debugger;
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );
    return selectAccount[0];
  };

  //Effect
  useEffect(() => {
    if (userProfile.accountType == "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/404-not-found");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/404-not-found");
      }
    }

    dispatch(FetchStudBookCountriesthunk(currentLanguage));
    dispatch(FetchCountriesthunk({ langauge: currentLanguage }));
    dispatch(FetchHorseColorsthunk({ langauge: currentLanguage }));

    let params = {
      ownerId: getUserID(),
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));

    let documentTypesParams = {
      id: serviceId,
      language: currentLanguage,
    };
    dispatch(FetchGetDocumentTypesByServiceIdthunk(documentTypesParams));

    //dispatch(FetchGetOwnersListthunk(currentLanguage));
  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 &&
      checkAnotherOwner.current
    ) {
      if (ownerHorsesDropDownData.length > 0) {
        setFormModel({
          ...formModel,
          horse: getServicesRequestByIdData.ownerShipTransfer.horseId,
          horseDrop: {
            label:
              getServicesRequestByIdData.ownerShipTransfer.horse
                .registrationNumber +
              " - " +
              getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
            value: getServicesRequestByIdData.ownerShipTransfer.horseId,
          },
          regNo:
            getServicesRequestByIdData.ownerShipTransfer.horse
              .registrationNumber,
          horseName: getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
          gender: getServicesRequestByIdData.ownerShipTransfer.horse.gender,
          color:
            currentLanguage == "en"
              ? getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                  .nameEn
              : getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                  .nameAr,
          birthDate:
            getServicesRequestByIdData.ownerShipTransfer.horse.birthDate,
          sire: getServicesRequestByIdData.ownerShipTransfer.horse.sireNameEn,
          dam: getServicesRequestByIdData.ownerShipTransfer.horse.damNameEn,

          employeeId:
            getServicesRequestByIdData.requestsType == "Portal"
              ? getServicesRequestByIdData.ownerShipTransfer.createdBy
              : getServicesRequestByIdData.ownerShipTransfer.ownerId,
          owner: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          ownerDrop: {
            label:
              getServicesRequestByIdData.ownerId +
              " - " +
              getServicesRequestByIdData.ownerName,
            value: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          },
          phone: getServicesRequestByIdData.ownerPhone,
          email: getServicesRequestByIdData.ownerEmail,
          idNumber: getServicesRequestByIdData.idNumber,
          eventName: getServicesRequestByIdData.eventName,
          comment: getServicesRequestByIdData.comment,
          requestsType: getServicesRequestByIdData.requestsType,
          referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
          buyerComment:
            getServicesRequestByIdData.ownerShipTransfer.buyerComment,
          address: getServicesRequestByIdData.ownerShipTransfer.address,
          mobile: getServicesRequestByIdData.ownerShipTransfer.mobile,
          isRegistration:
            getServicesRequestByIdData.ownerShipTransfer.isRegistration,
          filePath: getServicesRequestByIdData.ownerShipTransfer.filePath,
          fileType: getServicesRequestByIdData.ownerShipTransfer.fileType,
          fileName: getServicesRequestByIdData.ownerShipTransfer.fileName,
          breeder: getServicesRequestByIdData.ownerShipTransfer.horse.breeder,
          origin:
            getServicesRequestByIdData.ownerShipTransfer.horse.exportCountry,
          documentType:
            getServicesRequestByIdData.ownerShipTransfer.documentTypeId,
          documentTypeDrop: {
            label:
              getServicesRequestByIdData.ownerShipTransfer.documentTypeName,
            value: getServicesRequestByIdData.ownerShipTransfer.documentTypeId,
          },
        });
      } else {
        setFormModel({
          ...formModel,
          horse: getServicesRequestByIdData.ownerShipTransfer.horseId,
          horseDrop: {
            label:
              getServicesRequestByIdData.ownerShipTransfer.horse
                .registrationNumber +
              " - " +
              getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
            value: getServicesRequestByIdData.ownerShipTransfer.horseId,
          },
          regNo:
            getServicesRequestByIdData.ownerShipTransfer.horse
              .registrationNumber,
          horseName: getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
          gender: getServicesRequestByIdData.ownerShipTransfer.horse.gender,
          color:
            currentLanguage == "en"
              ? getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                  .nameEn
              : getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                  .nameAr,
          birthDate:
            getServicesRequestByIdData.ownerShipTransfer.horse.birthDate,
          sire: getServicesRequestByIdData.ownerShipTransfer.horse.sireNameEn,
          dam: getServicesRequestByIdData.ownerShipTransfer.horse.damNameEn,

          employeeId:
            getServicesRequestByIdData.requestsType == "Portal"
              ? getServicesRequestByIdData.ownerShipTransfer.createdBy
              : getServicesRequestByIdData.ownerShipTransfer.ownerId,
          owner: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          ownerDrop: {
            label:
              getServicesRequestByIdData.ownerId +
              " - " +
              getServicesRequestByIdData.ownerName,
            value: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          },
          phone: getServicesRequestByIdData.ownerPhone,
          email: getServicesRequestByIdData.ownerEmail,
          idNumber: getServicesRequestByIdData.idNumber,
          eventName: getServicesRequestByIdData.eventName,
          requestsType: getServicesRequestByIdData.requestsType,
          comment: getServicesRequestByIdData.comment,
          referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
          buyerComment:
            getServicesRequestByIdData.ownerShipTransfer.buyerComment,
          address: getServicesRequestByIdData.ownerShipTransfer.address,
          mobile: getServicesRequestByIdData.ownerShipTransfer.mobile,
          isRegistration:
            getServicesRequestByIdData.ownerShipTransfer.isRegistration,
          filePath: getServicesRequestByIdData.ownerShipTransfer.filePath,
          fileType: getServicesRequestByIdData.ownerShipTransfer.fileType,
          fileName: getServicesRequestByIdData.ownerShipTransfer.fileName,
          breeder: getServicesRequestByIdData.ownerShipTransfer.horse.breeder,
          origin:
            getServicesRequestByIdData.ownerShipTransfer.horse.exportCountry,
          documentType:
            getServicesRequestByIdData.ownerShipTransfer.documentTypeId,
          documentTypeDrop: {
            label:
              getServicesRequestByIdData.ownerShipTransfer.documentTypeName,
            value: getServicesRequestByIdData.ownerShipTransfer.documentTypeId,
          },
        });
      }

      if (
        getServicesRequestByIdData.ownerShipTransfer.filePath != null &&
        getServicesRequestByIdData.ownerShipTransfer.filePath !== ""
      ) {
        dispatch(
          FetchGetFilebase64thunk(
            getServicesRequestByIdData.ownerShipTransfer.filePath
          )
        );
        // setUploadAttachmentFile({
        //   file: getServicesRequestByIdData.ownerShipTransfer.attachmentUrl,
        //   name: getServicesRequestByIdData.ownerShipTransfer.attachmentUrl.substr(
        //     getServicesRequestByIdData.ownerShipTransfer.attachmentUrl.lastIndexOf(
        //       "/"
        //     ) + 1
        //   ),
        // });
      }

      if (
        getServicesRequestByIdData.ownerShipTransfer.ownerId !==
          getServicesRequestByIdData.ownerShipTransfer.createdBy &&
        subOwnerProfilesByIdData.length > 0
      ) {
        let params = {
          ownerId: getServicesRequestByIdData.ownerShipTransfer.ownerId,
          skip: 0,
          take: 1000,
          langauge: currentLanguage,
          horseTypeFilter: "1",
          searchValue: "",
          sortBy: "-1",
        };
        dispatch(FetchOwnerHorsesDropDownthunk(params));

        //update checkAnotherOwner
        checkAnotherOwner.current = false;
        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.ownerShipTransfer.ownerId
            ).registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.ownerShipTransfer.ownerId
            )[`text${currentLanguage == "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.ownerShipTransfer.ownerId
          ).value,
        });
      }

      checkAnotherOwner.current = false;

      setCheckedItems({
        //option1: true,
        option2: true,
        option3: true,
      });
    }
  }, [
    ownerHorsesDropDownData,
    getServicesRequestByIdData,
    subOwnerProfilesByIdData,
    getDocumentTypesByServiceIdData,
  ]);

  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, []);

  useEffect(() => {
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_status.current === "BuyerApproved") {
        toast.success(
          translate(
            "web.yourRequestHasSuccessfully",
            "Your Request Has Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_status.current === "BuyerApproved") {
          //go to payment
          //const returnUrl = `${BaseURL}/servicesrequestspaymentsuccessful?serviceRequestId=${id}`;
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=7&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }
  }, [toasterMessageServicesRequests]);

  const dismissAll = () => toast.dismiss();

  // asyunc search
  const promiseOptions = (searchKeyword) =>
    new Promise((resolve) => {
      if (timeoutId > 0) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(async () => {
        const response = await axiosInstance.post(
          "/StudBook/FilterOwnersOrUsers",
          {
            langauge: localStorage.getItem("eahsLang") ?? "en",
            ownerId: searchKeyword,
            skip: 0,
            take: 10000,
            tableType: "user",
          }
        );

        const data = await response.data.ownersList;

        const options = data.map((item) => ({
          label: item.ownerId + " - " + item.name,
          value: item.id,
          details: item,
        }));
        resolve(options);
      }, 1000);

      setTimeoutId(id);
    });

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${
          servicesRequestsLoading || ownerListLoading
            ? "classdisplay"
            : "classnotdisplay"
        }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />
      {
        //content page
      }
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl ">
          {
            //convering certificate title
          }
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage == "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>
          {
            //stallion details title
          }
          <h2 className="fontWeight colorred">
            {translate("web.stallionDetails", "Stallion Details")}
          </h2>
          {
            //stallion content
          }
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
            {
              //stallion radio buttons
            }
            <div className="AuctionFilterRadioContainer mt-3">
              <div style={{ margin: "auto 0" }}>
                <NetaqInput
                  name={"stallionType"}
                  key={"Registered"}
                  type={"radio"}
                  value={"Registered"}
                  label={translate("web.registered", "Registered")}
                  onChange={(e) => handleFormChange(e, "stallionType")}
                  checked={
                    formModel.isStallionRegistered === true ? true : false
                  }

                  // onChange={(e) => setStallionChecker(true)}
                  // checked={stallionChecker === true ? true : false}
                />
              </div>
              <div style={{ margin: "auto 30px" }}>
                <NetaqInput
                  name={"stallionType"}
                  key={"Unregistered"}
                  type={"radio"}
                  value={"Unregistered"}
                  label={translate("web.unregistered", "Unregistered")}
                  onChange={(e) => handleFormChange(e, "stallionType")}
                  checked={
                    formModel.isStallionRegistered === false ? true : false
                  }
                  // onChange={(e) => {
                  //   setStallionChecker(false);
                  // }}
                  // checked={stallionChecker === false ? true : false}
                />
              </div>
            </div>
            {
              //stallion content registered
            }
            <div
              className="row mx-0 col-md-12 col-sm-12 mt-5 rtl"
              style={{
                display: formModel.isStallionRegistered == true ? "" : "none",
              }}
            >
              {
                //stallion details
              }
              <div className="col-md-12 col-sm-12 rtl p0">
                <NetaqInput
                  type={"checkbox"}
                  label={translate(
                    "web.notStallionOwner",
                    "i'm not the stallion's owner"
                  )}
                  name="notStallionOwner"
                  checked={formModel.notStallionOwner}
                  onChange={(e) => handleFormChange(e, "notStallionOwner")}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label>
                  {translate("web.stallion", "Stallion")}
                  <i className="colorred">*</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.horse", "Horse"),
                      value: null,
                    },
                    ...(!!ownerHorsesDropDownData &&
                      ownerHorsesDropDownData.map((a) => ({
                        label: a.registerNumber + " - " + a.name,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.horseStallionRegDrop}
                  onChange={(e) => handleFormChange(e, "horseStallion")}
                  placeholder={translate("web.horse", "Horse")}
                  name={"horse"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  autoFocus={true}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.origin", "Origin")}
                  name={"origin"}
                  type={"text"}
                  disabled={true}
                  value={formModel.originStallionReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.breeder", "Breeder")}
                  name={"breeder"}
                  type={"text"}
                  disabled={true}
                  value={formModel.breederStallionReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.gender", "Gender")}
                  name={"gender"}
                  type={"text"}
                  disabled={true}
                  value={translate(
                    `web.${formModel.genderStallionReg.toLowerCase()}`,
                    formModel.genderStallionReg
                  )}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.color", "Color")}
                  name={"color"}
                  type={"text"}
                  disabled={true}
                  value={formModel.colorStallionReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.birthDate", "Birth Date")}
                  name={"birthDate"}
                  type={"text"}
                  disabled={true}
                  value={
                    formModel.birthDateStallionReg != null &&
                    formModel.birthDateStallionReg !== ""
                      ? formModel.birthDateStallionReg &&
                        formatDate(formModel.birthDateStallionReg)
                      : ""
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.sire", "Sire")}
                  name={"sire"}
                  type={"text"}
                  disabled={true}
                  value={formModel.sireStallionReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.dam", "Dam")}
                  name={"dam"}
                  type={"text"}
                  disabled={true}
                  value={formModel.damStallionReg}
                />
              </div>
              <div
                className="col-md-6 col-sm-12"
                style={{
                  display: formModel.notStallionOwner == true ? "" : "none",
                }}
              >
                <NetaqInput
                  label={translate("web.ownerName", "Owner Name")}
                  name={"ownerName"}
                  type={"text"}
                  disabled={true}
                  value={formModel.ownerNameStallionReg}
                />
              </div>
              <div
                className="col-md-6 col-sm-12"
                style={{
                  display: formModel.notStallionOwner == true ? "" : "none",
                }}
              >
                <NetaqInput
                  label={translate("web.ownerEmail", "ownerEmail")}
                  name={"ownerEmail"}
                  type={"text"}
                  disabled={true}
                  value={formModel.ownerEmailStallionReg}
                />
              </div>
              <div
                className="col-md-6 col-sm-12"
                style={{
                  display: formModel.notStallionOwner == true ? "" : "none",
                }}
              >
                <NetaqInput
                  label={translate("web.ownerMobile", "ownerMobile")}
                  name={"ownerMobile"}
                  type={"text"}
                  disabled={true}
                  value={formModel.ownerMobileStallionReg}
                />
              </div>
            </div>
            {
              //stallion content unregistered
            }
            <div
              className="row mx-0 col-md-12 col-sm-12 mt-3 rtl"
              style={{
                display: formModel.isStallionRegistered == false ? "" : "none",
              }}
            >
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.englishName", "English Name")}
                  name={"horseNameEnStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.horseNameEnStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.arabicName", "Arabic Name")}
                  name={"horseNameArStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.horseNameArStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.regNo", "Reg.No")}
                  name={"currentRegNoStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.currentRegNoStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.damEn", "Dam En")}
                  name={"damEnStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.damEnStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.damAr", "Dam Ar")}
                  name={"damArStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.damArStallionUnReg}
                />
              </div>

              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.sireEn", "Sire En")}
                  name={"sireEnStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.sireEnStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.sireAr", "Sire Ar")}
                  name={"sireArStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.sireArStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.ueln", "UELN")}
                  name={"uelnStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.uelnStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.ownerNameEn", "Owner Name En")}
                  name={"ownerNameEnStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.ownerNameEnStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.ownerNameAr", "Owner Name Ar")}
                  name={"ownerNameArStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.ownerNameArStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.horseAddress", "Horse Address")}
                  name={"horseAddressStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.horseAddressStallionUnReg}
                />
              </div>

              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.breederNameEn", "breederNameEn")}
                  name={"breederNameEnStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.breederNameEnStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.breederNameAr", "breederNameAr")}
                  name={"breederNameArStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.breederNameArStallionUnReg}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  name={"birthDateStallionUnReg"}
                  type={"normalDate"}
                  label={translate("web.birthDate", "Birth Date")}
                  onChange={(e) => handleFormChange(e, "birthDate")}
                  //maxDate={dayjs(showStartDate)}
                  value={normalDateFormat(birthDate)}
                  required={true}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label>
                  {translate("web.color", "Color")}
                  <i className="colorred">*</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.color", "Color"),
                      value: null,
                    },
                    ...(!!horseColorsData &&
                      horseColorsData.map((a) => ({
                        label: a.nameEn,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.colorStallionUnRegDrop}
                  onChange={(e) => handleFormChange(e, "color")}
                  placeholder={translate("web.color", "Color")}
                  name={"color"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                />
              </div>
            </div>
          </div>
          {
            ////////////////////////
          }
          {
            //mare details title
          }
          <h2 className="fontWeight colorred">
            {translate("web.mareDetails", "Mare Details")}
          </h2>
          {
            //mare content
          }
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
            {
              //mare radio buttons
            }
            <div className="AuctionFilterRadioContainer mt-3">
              <div style={{ margin: "auto 0" }}>
                <NetaqInput
                  name={"mareType"}
                  key={"Registered"}
                  type={"radio"}
                  value={"Registered"}
                  label={translate("web.registered", "Registered")}
                  onChange={(e) => handleFormChange(e, "mareType")}
                  checked={formModel.isMareRegistered === true ? true : false}
                  // onChange={(e) => setMareChecker(true)}
                  // checked={mareChecker === true ? true : false}
                />
              </div>
              <div style={{ margin: "auto 30px" }}>
                <NetaqInput
                  name={"mareType"}
                  key={"Unregistered"}
                  type={"radio"}
                  value={"Unregistered"}
                  label={translate("web.unregistered", "Unregistered")}
                  onChange={(e) => handleFormChange(e, "mareType")}
                  checked={formModel.isMareRegistered === false ? true : false}
                  // onChange={(e) => {
                  //   setMareChecker(false);
                  // }}
                  // checked={mareChecker === false ? true : false}
                />
              </div>
            </div>
            {
              //mare content registered
            }
            <div
              className="row mx-0 col-md-12 col-sm-12 mt-5 rtl"
              style={{
                display: formModel.isMareRegistered == true ? "" : "none",
              }}
              // style={{
              //   display: mareChecker == true ? "" : "none",
              // }}
            >
              {
                //mare details
              }
              <div className="col-md-6 col-sm-12 ">
                <label>
                  {translate("web.horse", "Horse")}
                  <i className="colorred">*</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.horse", "Horse"),
                      value: "",
                    },
                    ...(!!ownerHorsesDropDownData &&
                      ownerHorsesDropDownData.map((a) => ({
                        label: a.registerNumber + " - " + a.name,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.horseDrop}
                  onChange={(e) => handleFormChange(e, "horse")}
                  placeholder={translate("web.horse", "Horse")}
                  name={"horse"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  autoFocus={true}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.origin", "Origin")}
                  name={"origin"}
                  type={"text"}
                  disabled={true}
                  value={formModel.origin}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.breeder", "Breeder")}
                  name={"breeder"}
                  type={"text"}
                  disabled={true}
                  value={formModel.breeder}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.gender", "Gender")}
                  name={"gender"}
                  type={"text"}
                  disabled={true}
                  value={translate(
                    `web.${formModel.gender.toLowerCase()}`,
                    formModel.gender
                  )}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.color", "Color")}
                  name={"color"}
                  type={"text"}
                  disabled={true}
                  value={formModel.color}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.birthDate", "Birth Date")}
                  name={"birthDate"}
                  type={"text"}
                  disabled={true}
                  value={
                    formModel.birthDate != null && formModel.birthDate !== ""
                      ? formModel.birthDate && formatDate(formModel.birthDate)
                      : ""
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.sire", "Sire")}
                  name={"sire"}
                  type={"text"}
                  disabled={true}
                  value={formModel.sire}
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <NetaqInput
                  label={translate("web.dam", "Dam")}
                  name={"dam"}
                  type={"text"}
                  disabled={true}
                  value={formModel.dam}
                />
              </div>
            </div>
            {
              //mare content unregistered
            }
            <div
              className="row mx-0 col-md-12 col-sm-12 mt-3 rtl"
              style={{
                display: formModel.isMareRegistered == false ? "" : "none",
              }}
              // style={{
              //   display: mareChecker == false ? "" : "none",
              // }}
            >
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.englishName", "English Name")}
                  name={"mareEnglishName"}
                  type={"text"}
                  disabled={true}
                  value={formModel.origin}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.arabicName", "Arabic Name")}
                  name={"mareArabicName"}
                  type={"text"}
                  value={formModel.origin}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.regNo", "Reg.No")}
                  name={"mareRegNo"}
                  type={"text"}
                  value={formModel.origin}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.dam", "Dam")}
                  name={"mareDam"}
                  type={"text"}
                  value={formModel.origin}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.sire", "Sire")}
                  name={"mareSire"}
                  type={"text"}
                  value={formModel.origin}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.color", "Color")}
                  name={"mareColor"}
                  type={"text"}
                  value={formModel.origin}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.ownerName", "Owner Name")}
                  name={"mareOwnerName"}
                  type={"text"}
                  value={formModel.origin}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <NetaqInput
                  name={"mareBirthDate"}
                  type={"date"}
                  label={translate("web.birthDate", "birth Date")}
                  onChange={(e) => handleFormChange(e, "birthDate")}
                  //maxDate={dayjs(showStartDate)}
                  //value={birthDate}
                  required={true}
                  //disabled={checkHorse ? false : true}
                />
              </div>
            </div>
          </div>
          {
            ////////////////////////
          }
          {
            //covering details title
          }
          <h2 className="fontWeight colorred">
            {translate("web.coveringDetails", "Covering Details")}
          </h2>
          {
            //covering details content
          }
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
            {
              //type Of Covering
            }
            <div className="col-md-6 col-sm-12 rtl mb-5">
              <h2 className="fontWeight colorred">
                {translate("web.typeOfCovering", "Type Of Covering")}
              </h2>
              <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                <NetaqInput
                  name={"typeOfCovering"}
                  key={"NaturalBreeding"}
                  type={"radio"}
                  value={"NaturalBreeding"}
                  label={translate(
                    "web.naturalBreeding",
                    "Natural (In-Hand) Breeding"
                  )}
                  onChange={(e) => handleFormChange(e, "typeOfCovering")}
                  checked={formModel.isRegistration === true ? true : false}
                />
              </div>
              <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                <NetaqInput
                  name={"typeOfCovering"}
                  key={"ArtificialInseminationLocal"}
                  type={"radio"}
                  value={"ArtificialInseminationLocal"}
                  label={translate(
                    "web.artificialInseminationLocal",
                    "Artificial Insemination (Locally Collected Semen)"
                  )}
                  onChange={(e) => handleFormChange(e, "typeOfCovering")}
                  checked={formModel.isRegistration === true ? true : false}
                />
              </div>
              <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                <NetaqInput
                  name={"typeOfCovering"}
                  key={"ArtificialInseminationImported"}
                  type={"radio"}
                  value={"ArtificialInseminationImported"}
                  label={translate(
                    "web.artificialInseminationImported",
                    "Artificial Insemination (Imported Semen)"
                  )}
                  onChange={(e) => handleFormChange(e, "typeOfCovering")}
                  checked={formModel.isRegistration === true ? true : false}
                />
              </div>
            </div>
            {
              //covering date
            }
            <div className="col-md-4 col-sm-12 rtl mb-5">
              <NetaqInput
                name={"coveringDate"}
                type={"date"}
                label={translate("web.coveringDate", "Covering Date")}
                onChange={(e) => handleFormChange(e, "coveringDate")}
                //maxDate={dayjs(showStartDate)}
                //value={birthDate}
                required={true}
                //disabled={checkHorse ? false : true}
              />
            </div>

            {
              //breeding happend abroad
            }
            <div className="col-md-3 col-sm-12 row rtl">
              <div className="col-md-12 col-sm-12 rtl p0">
                <NetaqInput
                  type={"checkbox"}
                  label={translate(
                    "web.breedingHappendAbroad",
                    "The Breeding happend abroad"
                  )}
                  name="option2"
                  checked={checkedItems.option2}
                  onChange={handleCheckboxChange}
                />
              </div>
              {
                //document file title breeding happend abroad
              }
              <div className="col-md-12 font-15 Gray_color">
                {translate(
                  "web.foreignCertificate",
                  "Foreign Certificate (pdf,png)"
                )}
              </div>
              {
                //document file button breeding happend abroad
              }
              <div className="col-md-12 col-sm-12 ">
                <UploadFile
                  text={"Upload"}
                  className="maxWidth150 mt-10"
                  ismultiFile={false}
                  disabled={
                    action == "view" ||
                    (action === "approve" && formModel.eventName != "SentBack")
                      ? true
                      : false
                  }
                  onChange={handleUploadBreedingHappendAbroadChange}
                  iconClassName={"fa-solid fa-upload fa-lg marginrightleft-5"}
                  accept={"image/*,application/pdf"}
                />
              </div>

              {
                //document file breeding happend abroad
              }
              <div className="col-md-12 col-sm-12 mb-25 ">
                {
                  //document file befor save
                }
                {breedingHappendAbroadAttachmentFile != null && (
                  <Link
                    to={breedingHappendAbroadAttachmentFile.file}
                    className="C89C66 flex-center cursorPointer mb-25"
                    target="_blank"
                  >
                    <i className="fa fa-file fa-3x"></i>
                    <span className="black-color fontsize18 marginrightleft-10">
                      {breedingHappendAbroadAttachmentFile.name}
                    </span>
                  </Link>
                )}
              </div>
            </div>
            {
              //embryo is imported
            }
            <div className="col-md-3 col-sm-12 row rtl">
              <div className="col-md-12 col-sm-12 rtl p0">
                <NetaqInput
                  type={"checkbox"}
                  label={translate(
                    "web.embryoIsImported",
                    "The embryo is imported in the utero"
                  )}
                  name="option2"
                  checked={checkedItems.option2}
                  onChange={handleCheckboxChange}
                />
              </div>
              {
                //document file title embryo is imported
              }
              <div className="col-md-12 font-15 Gray_color">
                {translate(
                  "web.foreignCertificate",
                  "Foreign Certificate (pdf,png)"
                )}
              </div>
              {
                //document file button embryo is imported
              }
              <div className="col-md-12 col-sm-12">
                <UploadFile
                  text={"Upload"}
                  className="maxWidth150 mt-10"
                  ismultiFile={false}
                  onChange={handleUploadEmbryoIsImportedChange}
                  iconClassName={"fa-solid fa-upload fa-lg marginrightleft-5"}
                  accept={"image/*,application/pdf"}
                />
              </div>
              {
                //document file embryo is imported
              }
              <div className="col-md-12 col-sm-12">
                {embryoIsImportedAttachmentFile != null && (
                  <Link
                    to={embryoIsImportedAttachmentFile.file}
                    className="C89C66 flex-center cursorPointer mb-25"
                    target="_blank"
                  >
                    <i className="fa fa-file fa-3x"></i>
                    <span className="black-color fontsize18 marginrightleft-10">
                      {embryoIsImportedAttachmentFile.name}
                    </span>
                  </Link>
                )}
              </div>
            </div>
            {
              //stallion owner signed permit
            }
            <div className="col-md-3 col-sm-12 row rtl mt-5">
              {
                //document file title stallion owner signed permit
              }
              <div className="col-md-12 font-15 Gray_color">
                {translate(
                  "web.stallionOwnerSignedPermit",
                  "Stallion Owner Signed Permit (pdf,png)"
                )}
              </div>
              {
                //document file button stallion owner signed permit
              }
              <div className="col-md-12 col-sm-12 ">
                <UploadFile
                  text={"Upload"}
                  className="maxWidth150 mt-10"
                  ismultiFile={false}
                  disabled={
                    action == "view" ||
                    (action === "approve" && formModel.eventName != "SentBack")
                      ? true
                      : false
                  }
                  onChange={handleUploadStallionOwnerSignedPermitChange}
                  iconClassName={"fa-solid fa-upload fa-lg marginrightleft-5"}
                  accept={"image/*,application/pdf"}
                />
              </div>
              {
                //document file stallion owner signed permit
              }
              <div className="col-md-12 col-sm-12 mb-25 ">
                {
                  //document file befor save
                }
                {stallionOwnerSignedPermitAttachmentFile != null && (
                  <Link
                    to={stallionOwnerSignedPermitAttachmentFile.file}
                    className="C89C66 flex-center cursorPointer mb-25"
                    target="_blank"
                  >
                    <i className="fa fa-file fa-3x"></i>
                    <span className="black-color fontsize18 marginrightleft-10">
                      {stallionOwnerSignedPermitAttachmentFile.name}
                    </span>
                  </Link>
                )}
              </div>
            </div>
            {
              // imported Semen Certificate
            }
            <div className="col-md-3 col-sm-12 row rtl mt-5">
              {
                //document file title imported Semen Certificate
              }
              <div className="col-md-12 font-15 Gray_color">
                {translate(
                  "web.importedSemenCertificate",
                  "Imported Semen Certificate (pdf,png)"
                )}
              </div>
              {
                //document file button imported Semen Certificate
              }
              <div className="col-md-12 col-sm-12">
                <UploadFile
                  text={"Upload"}
                  className="maxWidth150 mt-10"
                  ismultiFile={false}
                  onChange={handleUploadImportedSemenCertificateChange}
                  iconClassName={"fa-solid fa-upload fa-lg marginrightleft-5"}
                  accept={"image/*,application/pdf"}
                />
              </div>
              {
                //document file imported Semen Certificate
              }
              <div className="col-md-12 col-sm-12 mb-25 ">
                {
                  //document file befor save
                }
                {importedSemenCertificateAttachmentFile != null && (
                  <Link
                    to={importedSemenCertificateAttachmentFile.file}
                    className="C89C66 flex-center cursorPointer mb-25"
                    target="_blank"
                  >
                    <i className="fa fa-file fa-3x"></i>
                    <span className="black-color fontsize18 marginrightleft-10">
                      {importedSemenCertificateAttachmentFile.name}
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </div>

          {
            // buttons
          }
          <div className="col-md-12 col-sm-12 mt-15 text-end">
            <ActionButton
              type={"requests"}
              className="btn buttonformshowentrycard height46 linkbtn"
              label={translate("web.draft", "Draft")}
              text={
                <React.Fragment>
                  <i className="fa fa-save fa-lg marginrightleft-10"></i>
                  {translate("web.draft", "Draft")}
                </React.Fragment>
              }
              margin={"auto 10px"}
              width={"250px"}
              onClick={() => handelFormSubmit("Draft")}
              disabled={
                formModel.eventName === "" || formModel.eventName === "Draft"
                  ? false
                  : true
              }
            />
            <ActionButton
              type={"requests"}
              className="btn-success text-white requestBtn"
              label={translate("web.submit", "Submit")}
              text={
                <React.Fragment>
                  <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                  {translate("web.submit", "Submit")}
                </React.Fragment>
              }
              margin={"auto 25px"}
              width={"200"}
              onClick={() =>
                handelFormSubmit(
                  action == "edit" && formModel.eventName == "BuyerRejected"
                    ? "WatingBuyerApproval"
                    : "WatingBuyerApproval"
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
