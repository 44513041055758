// import React, { useEffect, useState } from "react";
// import axiosInstance from "../axios/axios";
// import Loader from "./Loader";
// import { useTranslate } from "./TranslationText";
// import ActionButton from "./ActionButton";
// import NetaqInput from "./NetaqInput";
// import { formatDate } from "../helper/formatDate";
// import { convertToCamelCase } from "../helper/convertToCamelCase";

// export default function ParticipateHistory() {
//   const { translate } = useTranslate();
//   const [ParticipateHistory, setParticipateHistory] = useState([]);
//   // filter params

//   const [totalCount, setTotalCount] = useState(0);
//   const [skip, setSkip] = useState(0);
//   const itemsPerPage = 20;
//   const [take, settake] = useState(itemsPerPage);
//   const [searchByHorseName, setSearchByHorseName] = useState(null);
//   const [searchByShowName, setSearchByShowName] = useState(null);
//   const [fromDate, setFromDate] = useState(null);
//   const [toDate, setToDate] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);

//   const [ParticipateLoaded, setParticipatesLoaded] = useState(false);
//   const [noParticipatesFound, setNoParticipatesFound] = useState(false);

//   async function getParticipateHistory() {
//     try {
//       const response = await axiosInstance.post(
//         "shows/FilterShowParticipantHistory", { skip: skip, take: take, showName: searchByShowName, horseName: searchByHorseName, startDate: fromDate?.toDate(), endDate: toDate?.toDate() }
//       );
//       if (response.data.responseCode == "200") {
//         const Participates = response.data.result;
//         setTotalCount(response.data.totalCount);

//         setParticipateHistory(Participates);
//         if (Participates.length === 0) {
//           setNoParticipatesFound(true);
//         }
//         setParticipatesLoaded(true);
//       } else {
//         setParticipatesLoaded(true);
//         console.log("error in loading Participates");
//       }
//     } catch (error) {
//       console.log("error in loading Participates", error);
//       setParticipatesLoaded(true);
//     }
//   }

//   useEffect(() => {
//     getParticipateHistory();
//   }, []);

//   const handleReset = () => {
//     setTotalCount(0);
//     setSkip(0);
//     settake(itemsPerPage);
//     setSearchByHorseName(null);
//     setSearchByShowName(null);
//     setFromDate(null);
//     setToDate(null);
//     setCurrentPage(1);
//   }
//   const handleSubmit = () => {
//     getParticipateHistory();
//   }



//   return (
//     <>
//       {ParticipateLoaded ? (
//         <div className="col-lg-12 col-md-12 col-sm-12  p0">
//           <h3 className="col-lg-12 col-md-12 col-sm-12 bold rtl p0">
//             {translate("web.ParticipateHistory", "Participate History")}
//           </h3>
//           {/* Filter controls */}
//           <div className="filter-controls rtl mb-3">
//             <div
//               className="row p0"
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "space-around",
//                 alignItems: "center",
//               }}
//             >
//               <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
//                 <NetaqInput
//                   type={"text"}

//                   label={translate("web.horseName", "Horse Name")}
//                   placeholder={translate(
//                     "web.horseName",
//                     "Horse Name"
//                   )}
//                   onChange={(e) => setSearchByHorseName(e.target.value)}
//                   value={searchByHorseName}
//                   name={translate("web.horseName", "Horse Name")}
//                 />
//               </div>
//               <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
//                 <NetaqInput
//                   type={"text"}

//                   label={translate("web.showName", "ShowName")}
//                   placeholder={translate(
//                     "web.showName",
//                     "ShowName"
//                   )}
//                   onChange={(e) => setSearchByShowName(e.target.value)}
//                   value={searchByShowName}
//                   name={translate("web.showName", "ShowName")}
//                 />
//               </div>
//               <div
//                 className="col-lg-1 col-md-2 col-sm-12 mt-2"
//                 style={{ minWidth: 160 }}
//               >
//                 <NetaqInput
//                   type="date"
//                   value={fromDate}
//                   onChange={(e) => setFromDate(e)}
//                   label={translate("web.from", "From ")}
//                   name={translate("web.from", "From ")}
//                 />
//               </div>
//               <div
//                 className="col-lg-1 col-md-2 col-sm-12 mt-2"
//                 style={{ minWidth: 160 }}
//               >
//                 <NetaqInput
//                   type="date"
//                   value={toDate}
//                   onChange={(e) => setToDate(e)}
//                   label={translate("web.to", "To ")}
//                   name={translate("web.to", "To ")}
//                 />
//               </div>
//               <div
//                 className="col-lg-2 col-md-2 col-sm-12 mt-3"
//                 style={{ display: "flex" }}
//               >
//                 <ActionButton
//                   type={"reset"}
//                   margin={"5px auto 0 auto"}
//                   label={translate("web.reset", "Reset")}
//                   text={translate("web.reset", "Reset")}
//                   onClick={handleReset}
//                 />
//               </div>

//               <div
//                 className="col-lg-2 col-md-2 col-sm-12 mt-3"
//                 style={{ display: "flex" }}
//               >
//                 <ActionButton
//                   type={"submit"}
//                   margin={"5px auto 0 auto"}
//                   label={translate("web.apply", "Apply")}
//                   text={translate("web.apply", "Apply")}
//                   onClick={handleSubmit}
//                 />
//               </div>

//             </div>
//           </div>
//           {ParticipateHistory.length > 0 ? (
//             <div className="scrolableTable">
//               <table className="table table-striped table-bordered rtl horseResultTable">
//                 <thead className="theadHorseSelect">
//                   <tr>
//                     <th className="text-center">
//                       {translate("web.horseName", "Horse Name")}
//                     </th>
//                     <th className="text-center">
//                       {translate("web.showName", "Show Name")}
//                     </th>
//                     <th className="text-center">
//                       {translate("web.showRank", "Show Rank")}
//                     </th>
//                     <th className="text-center">
//                       {translate("web.showDate", "Show Date")}
//                     </th>
//                     <th className="text-center">
//                       {translate("web.class", "Class")}
//                     </th>
//                     <th className="text-center">
//                       {translate("web.rank", "Rank")}
//                     </th>
//                     <th className="text-center">
//                       {translate("web.medal", "Medal")}
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody className="text-center">
//                   {ParticipateHistory.map((item, index) => (
//                     <tr key={index}>
//                       <td className="rtl">{item.horseName}</td>
//                       <td className="rtl">
//                         {item.showName}
//                       </td>
//                       <td className="rtl">
//                         {item.showRank}
//                       </td>
//                       <td className="rtl">
//                         {item.showStartDate}
//                       </td>
//                       <td className="rtl">
//                         {item.categoryClassName}
//                       </td>
//                       <td className="rtl">
//                         {item.rank}
//                       </td>
//                       <td className="rtl">
//                         {item.medal}
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//               {/* Pagination buttons */}
//               <div
//                 className="pagination-buttons rtl"
//                 style={{ display: "flex", alignItems: "center" }}
//               >
//                 <ActionButton
//                   type={"normalbtn"}

//                   className="btn"
//                   text={translate("web.prev", "Prev")}
//                   margin={"auto 10px"}
//                 />
//                 <span>{`${ParticipateHistory.length < 1000
//                   ? ParticipateHistory.length
//                   : 1 * ParticipateHistory.length
//                   } / ${totalCount/itemsPerPage}`}</span>
//                 <ActionButton
//                   type={"normalbtn"}
//                   margin={"auto 10px"}
//                   className="btn"
//                   text={translate("web.next", "Next")}
//                 />
//               </div>
//             </div>
//           ) : (
//             <div className="text-center text-danger">
//               {translate("web.nodataFound", "No Result Found")}
//             </div>
//           )}
//         </div>
//       ) : (
//         <Loader />
//       )}
//     </>
//   );
// }
import React, { useEffect, useState } from "react";
import axiosInstance from "../axios/axios";
import Loader from "./Loader";
import { useTranslate } from "./TranslationText";
import ActionButton from "./ActionButton";
import NetaqInput from "./NetaqInput";
import { getOrdinal } from "../helper/getOrdinal";
import { convertToCamelCase } from "../helper/convertToCamelCase";

export default function ParticipateHistory() {
  const { translate } = useTranslate();
  const [ParticipateHistory, setParticipateHistory] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const itemsPerPage = 20;
  const [take, settake] = useState(itemsPerPage);
  const [searchByHorseName, setSearchByHorseName] = useState(null);
  const [searchByShowName, setSearchByShowName] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ParticipateLoaded, setParticipatesLoaded] = useState(false);
  const [noParticipatesFound, setNoParticipatesFound] = useState(false);

  const lang = localStorage.getItem('eahsLang') ?? 'en';

  async function getParticipateHistory() {
    try {
      const response = await axiosInstance.post(
        "shows/FilterShowParticipantHistory", {
          skip: skip,
          take: take,
          showName: searchByShowName,
          horseName: searchByHorseName,
          startDate: fromDate?.toDate(),
          endDate: toDate?.toDate()
        }
      );
      if (response.data.responseCode === "200") {
        const Participates = response.data.result;
        setTotalCount(response.data.totalCount);
        setParticipateHistory(Participates);
        setNoParticipatesFound(Participates.length === 0);
        setParticipatesLoaded(true);
      } else {
        console.log("error in loading Participates");
        setParticipatesLoaded(true);
      }
    } catch (error) {
      console.log("error in loading Participates", error);
      setParticipatesLoaded(true);
    }
  }

  useEffect(() => {
    getParticipateHistory();
  }, [skip]); // Fetch data on `skip` change for pagination

  const handleReset = () => {
    setSearchByHorseName('');
    setSearchByShowName('');
    setFromDate(null);
    setToDate(null);
    setSkip(0);
    setCurrentPage(1);
    
    // Fetch data without filters after resetting
    axiosInstance.post("shows/FilterShowParticipantHistory", {
        skip: 0,
        take: itemsPerPage,
        showName: null,
        horseName: null,
        startDate: null,
        endDate: null
      })
      .then(response => {
        if (response.data.responseCode === "200") {
          const Participates = response.data.result;
          setTotalCount(response.data.totalCount);
          setParticipateHistory(Participates);
          setNoParticipatesFound(Participates.length === 0);
        } else {
          console.log("error in loading Participates");
        }
      })
      .catch(error => {
        console.log("error in loading Participates", error);
      })
      .finally(() => {
        setParticipatesLoaded(true);
      });
  };

  const handleSubmit = () => {
    setSkip(0); // reset to first page on submit
    setCurrentPage(1);
    getParticipateHistory();
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      setSkip((newPage - 1) * itemsPerPage);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalCount / itemsPerPage);
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      setSkip(newPage * itemsPerPage - itemsPerPage);
    }
  };

  return (
    <>
      {ParticipateLoaded ? (
        <div className="col-lg-12 col-md-12 col-sm-12 p0">
          <h3 className="col-lg-12 col-md-12 col-sm-12 bold rtl p0">
            {translate("web.participateHistory", "Participate History")}
          </h3>
          <div className="filter-controls rtl mb-3">
            <div
              className="row p0"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                <NetaqInput
                  type={"text"}
                  label={translate("web.horseName", "Horse Name")}
                  placeholder={translate("web.horseName", "Horse Name")}
                  onChange={(e) => setSearchByHorseName(e.target.value)}
                  value={searchByHorseName}
                  name={translate("web.horseName", "Horse Name")}
                />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                <NetaqInput
                  type={"text"}
                  label={translate("web.showName", "Show Name")}
                  placeholder={translate("web.showName", "Show Name")}
                  onChange={(e) => setSearchByShowName(e.target.value)}
                  value={searchByShowName}
                  name={translate("web.showName", "Show Name")}
                />
              </div>
              <div className="col-lg-1 col-md-2 col-sm-12 mt-2" style={{ minWidth: 160 }}>
                <NetaqInput
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e)}
                  label={translate("web.from", "From")}
                  name={translate("web.from", "From")}
                />
              </div>
              <div className="col-lg-1 col-md-2 col-sm-12 mt-2" style={{ minWidth: 160 }}>
                <NetaqInput
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e)}
                  label={translate("web.to", "To")}
                  name={translate("web.to", "To")}
                />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 mt-3" style={{ display: "flex" }}>
                <ActionButton
                  type={"resetandfetch"}
                  margin={"5px auto 0 auto"}
                  label={translate("web.reset", "Reset")}
                  text={translate("web.reset", "Reset")}
                  onClick={handleReset}
                />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 mt-3" style={{ display: "flex" }}>
                <ActionButton
                  type={"submit"}
                  margin={"5px auto 0 auto"}
                  label={translate("web.search", "Search")}
                  text={translate("web.search", "Search")}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
          <hr/>

          {ParticipateHistory.length > 0 ? (
            <div className="scrolableTable">
              <table className="table table-striped table-bordered rtl horseResultTable">
                <thead className="theadHorseSelect">
                  <tr>
                    <th className="text-center">
                      {translate("web.horseName", "Horse Name")}
                    </th>
                    <th className="text-center">
                      {translate("web.showName", "Show Name")}
                    </th>
                    <th className="text-center">
                      {translate("web.showrank", "Show Rank")}
                    </th>
                    <th className="text-center">
                      {translate("web.showDate", "Show Date")}
                    </th>
                    <th className="text-center">
                      {translate("web.categories", "Categories")} / {translate("web.classes", "Classes") }
                    </th>
                    <th className="text-center">
                      {translate("web.rank", "Rank")}
                    </th>
                    <th className="text-center">
                      {translate("web.medal", "Medal")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {ParticipateHistory.map((item, index) => (
                    <tr key={index}>
                      <td className="rtl">{item.horseName}</td>
                      <td className="rtl" >{item.showName}</td>
                      <td className="rtl">{item.showRank}</td>
                      <td className="rtl">{item.showStartDate}</td>
                      <td className="rtl">{item.categoryClassName}</td>
                      <td className="rtl">{!!item.rank ? getOrdinal(item.rank , lang) : ''}</td>
                      <td className="rtl">{!!item.medal ? translate(`web.${convertToCamelCase(item.medal)}` , item.medal) : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination-buttons rtl" style={{ display: "flex", alignItems: "center" }}>
                <ActionButton
                  type={"normalbtn"}
                  className="btn"
                  text={translate("web.prev", "Prev")}
                  margin={"auto 10px"}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                <span>{`${currentPage} / ${Math.ceil(totalCount / itemsPerPage)}`}</span>
                <ActionButton
                  type={"normalbtn"}
                  className="btn"
                  text={translate("web.next", "Next")}
                  margin={"auto 10px"}
                  onClick={handleNextPage}
                  disabled={currentPage === Math.ceil(totalCount / itemsPerPage)}
                />
              </div>
            </div>
          ) : (
            <div className="text-center text-danger">
              {translate("web.nodataFound", "No Result Found")}
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
