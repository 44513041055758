import React, { useState } from 'react'
import ActionButton from '../components/ActionButton';
import { getOrdinal } from '../helper/getOrdinal';
import { useTranslate } from '../components/TranslationText';

const BaseURL = process.env.REACT_APP_BASE_URL;

export default function AwardPage() {
    const [activeTab, setActiveTab] = useState(1);
    const [mainActiveTab, setMainActiveTab] = useState(1);
    const { translate } = useTranslate();

    const myarray = [{
        title: '100',
        aint: '90',
        bint: '80',
        bnat: '70',
        nnat: '60',
        cint: '55',
        cnat: '50',
        sporg: '45'
    }, {
        title: '90',
        aint: '80',
        bint: '70',
        bnat: '60',
        nnat: '55',
        cint: '50',
        cnat: '45',
        sporg: '40'
    }, {
        title: '80',
        aint: '70',
        bint: '60',
        bnat: '55',
        nnat: '50',
        cint: '45',
        cnat: '40',
        sporg: '35'
    }, {
        title: '70',
        aint: '60',
        bint: '55',
        bnat: '50',
        nnat: '45',
        cint: '40',
        cnat: '35',
        sporg: '25'
    }, {
        title: '60',
        aint: '55',
        bint: '50',
        bnat: '45',
        nnat: '40',
        cint: '35',
        cnat: '30',
        sporg: '25'
    },
    {
        title: '55',
        aint: '50',
        bint: '45',
        bnat: '40',
        nnat: '35',
        cint: '30',
        cnat: '25',
        sporg: '20'
    },
    {
        title: '50',
        aint: '45',
        bint: '45',
        bnat: '35',
        nnat: '30',
        cint: '25',
        cnat: '20',
        sporg: '15'
    },
    {
        title: '45',
        aint: '40',
        bint: '35',
        bnat: '30',
        nnat: '25',
        cint: '20',
        cnat: '15',
        sporg: '10'
    },
    {
        title: '40',
        aint: '35',
        bint: '30',
        bnat: '25',
        nnat: '20',
        cint: '15',
        cnat: '10',
        sporg: '5'
    },
    {
        title: '35',
        aint: '30',
        bint: '25',
        bnat: '20',
        nnat: '15',
        cint: '10',
        cnat: '5',
        sporg: '3'
    }
    ]

    const lang = localStorage.getItem('eahsLang') ?? 'en';

    return (
        <div className='container'>
            <div className='row rtl'>
                <div className='col-12 p0'>
                    <img src={`${BaseURL}/assets/img/EAHSAward.png`} style={{ width: '100%' }} />
                </div>
                <div className='col-12 mt-3 p0'>
                    {lang == 'en' ? <>
                        <h3 className='bold'>EAHS Award 2024 – 2025 (Private Studs)</h3>
                        <h5 className='italic' >(Awarding the Highest Total Points in the Season)</h5>
                    </> :
                        <>
                            <h3 className='bold'>جائزة جمعية الإمارات للخيول العربية – 2024-2025 (للمرابط الخاصة)</h3>
                            <h5 className='italic' >(أعلى مجموع نقاط الموسم)</h5>
                        </>}
                </div>
                <div className='col-12 p0 mt-2 currentFont'>
                    <div className="col-12 row AuctionDetailsRow AuctionDetailsRowTabs rtl p0 my-1" style={{ padding: 0 }}>
                        <div className="col-lg-2 col-md-2 col-sm-3 p0">
                            <span
                                className={`customTabStyle ${mainActiveTab == 1 ? 'customTabactive' : ''}`}
                                onClick={() => {
                                    setMainActiveTab(0);
                                    setTimeout(() => {
                                        setMainActiveTab(1);
                                    }, 200);
                                }}
                            >
                                {lang == 'en' ? 'Award’s rules' : 'قوانين الجائزة'}

                            </span>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-3 p0 ms-2">
                            <span
                                className={`customTabStyle ${mainActiveTab == 2 ? 'customTabactive' : ''}`}
                                onClick={() => {
                                    setMainActiveTab(0);
                                    setTimeout(() => {
                                        setMainActiveTab(2);
                                    }, 200);
                                }}
                            >
                                {lang == 'en' ? 'Points distribution table' : 'جدول توزيع النقاط'}

                            </span>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-3 p0 ms-2">
                            <span
                                className={`customTabStyle ${mainActiveTab == 3 ? 'customTabactive' : ''}`}
                                onClick={() => {
                                    setMainActiveTab(0);
                                    setTimeout(() => {
                                        setMainActiveTab(3);
                                    }, 200);
                                }}
                            >
                                {lang == 'en' ?
                                    'Prize money' : 'الجوائز المالية'
                                }
                            </span>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-3 p0 ms-2">
                            <span
                                className={`customTabStyle ${mainActiveTab == 4 ? 'customTabactive' : ''}`}
                                onClick={() => {
                                    setMainActiveTab(0);
                                    setTimeout(() => {
                                        setMainActiveTab(4);
                                    }, 200);
                                }}
                            >
                                {lang == 'en' ?
                                    'Live ranking' : 'Live Ranking'
                                }
                            </span>
                        </div>
                    </div>
                    {lang == 'en' ?
                        <>

                            {mainActiveTab == 1 &&
                                <>
                                    <ul className='mt-3'>
                                        <li>
                                            <p >
                                                Junior Female, Senior Female, Junior Male and Senior Male High Point Awards will be given to the horses which achieve the highest accumulated total points from qualifying shows throughout the season as per the table of points.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                The award will be confined to the horses owned by the private owners registered in EAHS.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Each owner can receive only one award.
                                            </p>
                                        </li>
                                        <li>
                                            <p className='bold'>
                                                In the event of a tie:
                                            </p>
                                            <ul>
                                                <li>
                                                    <p>
                                                        The Award will be given to the horse with the highest average total points in the classes of the qualifying shows in which the horse has participated.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        If there is still a tie, the Award will be given to the horse with the highest average points for type.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        If there is still a tie, the Award will be given to the horse with the highest average points for movement.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        If there is still a tie, the winner of the Award will be chosen by ballot.
                                                    </p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                </>
                            }
                        </> :

                        /*
                         

 ()
                        * */
                        <>

                            {mainActiveTab == 1 &&
                                <>
                                    <ul className='mt-3'>
                                        <li>
                                            <p >
                                                سيتم منح جائزة أفضل مهرة، أفضل فرس، أفضل مهر، وأفضل حصان للخيول الحائزة على أعلى النقاط خلال المشاركة وإجمالي النقاط المتراكمة من كل بطولة للموسم وفقاً لجدول النقاط.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                سيتم توزيع الجائزة على خيول ملاك الاسطبلات الخاصة المسجلة في الجمعية.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                يمكن لمالك واحد الحصول على جائزة واحدة فقط.
                                            </p>
                                        </li>
                                        <li>
                                            <p className='bold'>
                                                في حالة التعادل:
                                            </p>
                                            <ul>
                                                <li>
                                                    <p>
                                                        سيتم منح المركز الأعلى للخيل الحاصل على أعلى متوسط إجمالي للنقاط في الفئات المؤهلة.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        وإذا كان لا يزال التعادل قائماً فسيتم منح المركز الأعلى للخيل صاحب أكبر عدد من النقاط بالنسبة للنوع “الهوية".
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        في حالة استمرار التعادل فسيتم منح المركز الأعلى للخيل الذي سجل أكبر عدد من النقاط في معيار "الحركة".
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        وإذا استمر التعادل فسيتم تحديد صاحب المركز الأعلى عبر القرعة.
                                                    </p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </>
                            }
                        </>
                    }
                    {mainActiveTab == 2 &&
                        <>

                            <div className="scrolableTable mx-auto mt-4" style={{ maxWidth: 800 }}>
                                <table className="table table-striped table-bordered rtl horseResultTable">
                                    <thead className="theadHorseSelect">

                                        <tr>
                                            <th className="text-center">
                                                {lang == 'en' ? 'Rank' : 'المركز'}
                                            </th>
                                            <th className="text-center">
                                                Title
                                            </th>
                                            <th className="text-center">
                                                A. Int.
                                            </th>
                                            <th className="text-center">
                                                B. Int.
                                            </th>
                                            <th className="text-center">
                                                B. Nat
                                            </th>
                                            <th className="text-center">
                                                N. Nat
                                            </th>
                                            <th className="text-center">
                                                C. Int
                                            </th>
                                            <th className="text-center">
                                                C. Nat
                                            </th>
                                            <th className="text-center">
                                                Sp. Org
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {myarray.map((x, index) => (
                                            <tr >
                                                <td className="rtl text-center bold">{index + 1}</td>
                                                <td className="rtl text-center bold">
                                                    {x.title}
                                                </td>
                                                <td className="rtl text-center bold">
                                                    {x.aint}
                                                </td>
                                                <td className="rtl text-center bold">
                                                    {x.bint}
                                                </td>
                                                <td
                                                    className={`rtl text-center bold `}
                                                >
                                                    {x.bnat}
                                                </td>
                                                <td className="rtl text-center bold" >
                                                    {x.nnat}
                                                </td>
                                                <td className="rtl text-center bold" >
                                                    {x.cint}
                                                </td>
                                                <td className="rtl text-center bold">
                                                    {x.cnat}
                                                </td>
                                                <td className="rtl text-center bold">
                                                    {x.sporg}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }
                </div>
                {mainActiveTab == 3 &&
                    <div className='col-12 p0 mt-3'>
                        {lang == 'en' ? <h5 className='bold'> Number Of Awards <span className='text-danger bold'>40 Awards</span></h5> :
                            <h5 className='bold'> عدد الجوائز المالية <span className='text-danger bold'>40 جائزة</span></h5>}
                        {lang == 'en' ?
                            <h5 className='bold mt-3'><span className='text-danger bold'>10 Awards</span> for Each Category</h5>
                            :
                            <h5 className='bold mt-3'><span className='text-danger bold'>10 جوائز </span> لكل فئة من:</h5>}
                        <ul>
                            <li>
                                {lang == 'en' ? 'Junior Fillies' : 'المهرات'}
                            </li>
                            <li>
                                {lang == 'en' ? 'Senior Mares' : 'الأفراس'}
                            </li>
                            <li>
                                {lang == 'en' ?
                                    'Junior Colts' : 'الأمهار'
                                }
                            </li>
                            <li>
                                {lang == 'en' ?
                                    'Senior Stallion' : 'الفحول'
                                }
                            </li>
                        </ul>
                        {lang == 'en' ?
                            <h5 className='bold my-3'>Total Prize Money <span className='text-danger bold'> 3 Million AED</span></h5>
                            :
                            <h5 className='bold my-3'>مجموع الجوائز المالية <span className='text-danger bold'> 3 مليون درهم</span></h5>
                        }

                    </div>
                }
                {mainActiveTab == 4 &&
                    <>
                        {lang == 'en' ?
                            <h5 className='bold my-3'>Result For Season <span className='text-danger bold'> 2023 - 2024 Till Now</span></h5>
                            :
                            <h5 className='bold my-3'>نتائج موسم <span className='text-danger bold'> 2023 - 2024 لحد هذه اللحظة</span></h5>
                        }
                        <div className="col-12 row AuctionDetailsRow AuctionDetailsRowTabs rtl p0 m0" style={{ padding: 0 }}>
                            <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2">
                                <span
                                    className={`customTabStyle ${activeTab == 1 ? 'customTabactive' : ''}`}
                                    onClick={() => {
                                        setActiveTab(0);
                                        setTimeout(() => {
                                            setActiveTab(1);
                                        }, 200);
                                    }}
                                >
                                    {lang == 'en' ? 'Junior Fillies' : 'المهرات'}

                                </span>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2">
                                <span
                                    className={`customTabStyle ${activeTab == 2 ? 'customTabactive' : ''}`}
                                    onClick={() => {
                                        setActiveTab(0);
                                        setTimeout(() => {
                                            setActiveTab(2);
                                        }, 200);
                                    }}
                                >
                                    {lang == 'en' ? 'Senior Mares' : 'الأفراس'}

                                </span>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2">
                                <span
                                    className={`customTabStyle ${activeTab == 3 ? 'customTabactive' : ''}`}
                                    onClick={() => {
                                        setActiveTab(0);
                                        setTimeout(() => {
                                            setActiveTab(3);
                                        }, 200);
                                    }}
                                >
                                    {lang == 'en' ?
                                        'Junior Colts' : 'الأمهار'
                                    }
                                </span>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2">
                                <span
                                    className={`customTabStyle ${activeTab == 4 ? 'customTabactive' : ''}`}
                                    onClick={() => {
                                        setActiveTab(0);
                                        setTimeout(() => {
                                            setActiveTab(4);
                                        }, 200);
                                    }}
                                >
                                    {lang == 'en' ?
                                        'Senior Stallion' : 'الفحول'
                                    }
                                </span>
                            </div>
                        </div>

                        <div className='col-12 p0 mt-2 currentFont'>
                            <div className="scrolableTable px-2">
                                {(activeTab == 1 || activeTab == 2 || activeTab == 3 || activeTab == 4) &&
                                    <table className="table table-striped table-bordered rtl horseResultTable">
                                        <thead className="theadHorseSelect">

                                            <tr>
                                                <th className="text-center">
                                                    {translate('web.rank', 'Rank')}
                                                </th>
                                                <th className="text-center">
                                                    {translate('web.theHorse', 'Horse')}
                                                </th>
                                                <th className="text-center">
                                                    {translate('web.owner', 'Owner')}
                                                </th>
                                                <th className="text-center">
                                                    {translate('web.totalPoints', 'Total Points')}
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {Array.from([{ points: 10, prize: 150000 }, { points: 9, prize: 120000 }, { points: 8, prize: 100000 }, { points: 7, prize: 70000 }, { points: 6, prize: 60000 }, { points: 5, prize: 50000 }, { points: 4, prize: 50000 }, { points: 3, prize: 50000 }, { points: 2, prize: 50000 }, { points: 1, prize: 50000 }]).map((x, index) => (
                                                <tr >
                                                    <td className="rtl text-center">{getOrdinal(index + 1)}</td>
                                                    <td className="rtl text-center">
                                                        123{index + 4} - Horse Name
                                                    </td>
                                                    <td className="rtl text-center">
                                                        325{index + 8} - Owner Name
                                                    </td>
                                                    <td
                                                        className={`rtl text-center `}
                                                    >
                                                        {(x.points + 1) * 20}
                                                    </td>


                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
