import { createSlice } from "@reduxjs/toolkit";
import {
  FetchApproveRejectRequestthunk,
  FetchCreateOwnerShipTransferServicesRequeststhunk,
  FetchDeleteOwnerShipTransferServicesRequeststhunk,
  FetchGetAllServicesRequestsOwnerToApprovethunk,
  FetchGetAllServicesRequeststhunk,
  FetchGetDocumentTypesByServiceIdthunk,
  FetchGetFilebase64thunk,
  FetchGetFilethunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchUpdateOwnerShipTransferServicesRequeststhunk,
} from "./ServicesRequestsAction";

const initialState = {
  createOwnerShipTransferServicesRequestsData: {},
  updateOwnerShipTransferServicesRequestsData: {},
  getAllServicesRequestsData: [],
  getServicesRequestByIdData: {},
  getAllServicesRequestsOwnerToApproveData: [],
  getServiceDefinitionByIdData: {},
  servicesRequestsLoading: false,
  servicesRequestsError: null,
  toasterMessageServicesRequests: null,
  deleteOwnerShipTransferServicesRequestsData: null,
  approveRejectRequestData: null,
  getFileData: null,
  getFilebase64Data: null,
  getDocumentTypesByServiceIdData: [],
};

const ServicesRequestsSlice = createSlice({
  name: "ServicesRequests",
  initialState,
  reducers: {
    resetToasterMessageServicesRequests: (state) => {
      state.toasterMessageServicesRequests = null;
    },
    resetGetServicesRequestByIdData: (state) => {
      state.getServicesRequestByIdData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      FetchCreateOwnerShipTransferServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOwnerShipTransferServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createOwnerShipTransferServicesRequestsData =
          action.payload.result;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchCreateOwnerShipTransferServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createOwnerShipTransferServicesRequestsData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchGetAllServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getAllServicesRequestsData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getAllServicesRequestsData = [];
      }
    );

    builder.addCase(
      FetchGetServicesRequestByIdthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetServicesRequestByIdthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getServicesRequestByIdData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetServicesRequestByIdthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getServicesRequestByIdData = {};
      }
    );

    builder.addCase(
      FetchDeleteOwnerShipTransferServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchDeleteOwnerShipTransferServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.deleteOwnerShipTransferServicesRequestsData = action.payload;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchDeleteOwnerShipTransferServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.deleteOwnerShipTransferServicesRequestsData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchGetAllServicesRequestsOwnerToApprovethunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequestsOwnerToApprovethunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getAllServicesRequestsOwnerToApproveData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequestsOwnerToApprovethunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getAllServicesRequestsOwnerToApproveData = {};
      }
    );

    builder.addCase(
      FetchUpdateOwnerShipTransferServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchUpdateOwnerShipTransferServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.updateOwnerShipTransferServicesRequestsData =
          action.payload.result;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchUpdateOwnerShipTransferServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.updateOwnerShipTransferServicesRequestsData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(FetchApproveRejectRequestthunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
      state.toasterMessageServicesRequests = null;
    });
    builder.addCase(
      FetchApproveRejectRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.approveRejectRequestData = action.payload;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchApproveRejectRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.approveRejectRequestData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchGetServiceDefinitionByIdthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetServiceDefinitionByIdthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getServiceDefinitionByIdData = action.payload;
      }
    );
    builder.addCase(
      FetchGetServiceDefinitionByIdthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getServiceDefinitionByIdData = {};
      }
    );

    builder.addCase(FetchGetFilethunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
    });
    builder.addCase(FetchGetFilethunk.fulfilled, (state, action) => {
      state.servicesRequestsLoading = false;
      state.getFileData = action.payload;
    });
    builder.addCase(FetchGetFilethunk.rejected, (state, action) => {
      state.servicesRequestsLoading = false;
      state.servicesRequestsError = action.payload;
      state.getFileData = null;
    });

    builder.addCase(FetchGetFilebase64thunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
    });
    builder.addCase(FetchGetFilebase64thunk.fulfilled, (state, action) => {
      state.servicesRequestsLoading = false;
      state.getFilebase64Data = action.payload;
    });
    builder.addCase(FetchGetFilebase64thunk.rejected, (state, action) => {
      state.servicesRequestsLoading = false;
      state.servicesRequestsError = action.payload;
      state.getFilebase64Data = null;
    });

    builder.addCase(
      FetchGetDocumentTypesByServiceIdthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetDocumentTypesByServiceIdthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getDocumentTypesByServiceIdData = action.payload;
      }
    );
    builder.addCase(
      FetchGetDocumentTypesByServiceIdthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getDocumentTypesByServiceIdData = {};
      }
    );
  },
});

export const {
  resetToasterMessageServicesRequests,
  resetGetServicesRequestByIdData,
} = ServicesRequestsSlice.actions;
export default ServicesRequestsSlice.reducer;
