import React, { useEffect, useState } from "react";
import { useTranslate } from "./TranslationText";
import NetaqInput from "./NetaqInput";
import ActionButton from "./ActionButton";
import Loader from "./Loader";
import UploadFile from "./UploadFile";
import { Link } from "react-router-dom";
import { extractChars } from "../helper/extractChart";
import AsyncSelect from 'react-select/async';
import axiosInstance from "../axios/axios";


const GrantedPermissionClaimsComponent = ({ isInOwnerProfile = false, permissionsList, toast, agentPermissionClaimsChanged }) => {

    const [permissionClaims, setPermissionClaims] = useState([{
        index: 0,
        selectedPermissions: [],
        isValid: false
    }]);



    const { translate } = useTranslate();

    const addNewClaim = () => {
        const newClaim = {
            index: permissionClaims.length,
            selectedPermissions: []
        }
        setPermissionClaims([...permissionClaims, newClaim]);
    }

    const removeItem = (index) => {
        const leftPart = permissionClaims.slice(0, index);
        const rightPart = permissionClaims.slice(index + 1);
        const newClaims = [...leftPart, ...rightPart];
        setPermissionClaims(newClaims);
    }

    const validateClaim = (claim) => {
        const errorDefinitions = {
            "NoPermissionSelected": (claim) => Array.isArray(claim.selectedPermissions) && claim.selectedPermissions.length > 0,
            "NoOwnerSelected": (claim) => !!claim.selectedOwner && !!claim.selectedOwner.value,
            "NoAuthFilesSelected": (claim) => Array.isArray(claim.agentAuthFiles) && claim.agentAuthFiles.length > 0,
            "NoStartAndEndDatesSelected": (claim) => !!claim.fromDate && !!claim.toDate
        };

        const errors = Object.keys(errorDefinitions).filter(key => {
            let error = errorDefinitions[key];
            if (!error(claim)) return key;
        });

        return errors;
    }

    const updatePermissionClaim = (index, updatedClaim) => {

        const errors = validateClaim(updatedClaim);
        if (errors.length > 0) {
            updatedClaim.isValid = false;
            updatedClaim.errors = errors;
        } else {
            updatedClaim.isValid = true;
            updatedClaim.errors = [];
        }

        const leftPart = permissionClaims.slice(0, index);
        const rightPart = permissionClaims.slice(index + 1);
        const newClaims = [...leftPart, updatedClaim, ...rightPart];
        setPermissionClaims(newClaims);
    }

    useEffect(() => {
        agentPermissionClaimsChanged(permissionClaims);
    }, [permissionClaims])

    return (
        <>
            {
                permissionClaims.map((claim, index) => {
                    return (<GrantedPermissionClaimItem
                        claim={claim}
                        permissionsList={permissionsList}
                        canDeleteItem={permissionClaims.length > 1}
                        removeItem={removeItem}
                        toast={toast}
                        index={index}
                        updatePermissionClaim={updatePermissionClaim}
                        isInOwnerProfile={isInOwnerProfile}
                    />);
                })
            }
            <div className="col-lg-12 col-md-12 col-sm-12 text-right text-left-rtl rotate3D rtl" style={{ marginTop: 25 }}>
                <ActionButton type={'btn'} onClick={addNewClaim} text={translate('web.addNewRecord', 'Add New Record')} label={translate('web.addNewRecord', 'Add New Record')} />
            </div>
        </>
    );
};

const GrantedPermissionClaimItem = ({ claim, isInOwnerProfile, permissionsList, canDeleteItem, removeItem, toast, index, updatePermissionClaim }) => {
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [agentAuthFiles, setAgentAuthFiles] = useState([]);
    const [selectedOwner, setSelectedOwner] = useState(claim.selectedOwner);
    const [selectedPermissions, setSelectedPermissions] = useState(claim.selectedPermissions);
    const { translate } = useTranslate();
    const selectAllOption = { value: 'all', label: translate('web.selectAll', 'Select All'), text: translate('web.selectAll', 'Select All') };

    useEffect(() => {
        setFromDate(claim.fromDate);
        setToDate(claim.toDate);
        setAgentAuthFiles(claim.agentAuthFiles);
        setSelectedPermissions(claim.selectedPermissions);
        setSelectedOwner(claim.selectedOwner);
    }, [claim]);

    const verifyPoAFileSettings = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            let checkFileSize = true;
            const fileListHook = [];

            for (let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++) {
                if (e.target.files[fileIndex].size > 10 * 1024 * 1024) {
                    checkFileSize = false;
                    toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
                } else {
                    fileListHook.push({
                        documentFile: URL.createObjectURL(e.target.files[fileIndex]),
                        name: e.target.files[fileIndex].name,
                        file: e.target.files[fileIndex],
                    });
                }
            }

            setAgentAuthFiles(fileListHook);
            updatePermissionClaim(index, { ...claim, agentAuthFiles: fileListHook })
        }
    };

    // asyunc search
    const [inputValue, setInputValue] = useState('');
    const [timeoutId, setTimeoutId] = useState(0);
    // // Function to load options based on input
    // timeoutId > 0 && clearTimeout(timeoutId);
    const promiseOptions = (searchKeyword) =>
        new Promise((resolve) => {
            if (timeoutId > 0) {
                clearTimeout(timeoutId);
            }
            const id = setTimeout(async () => {
                const response = await axiosInstance.post('/StudBook/FilterOwners', { langauge: localStorage.getItem('eahsLang') ?? 'en', ownerId: searchKeyword, skip: 0, take: 10000 })
                const data = await response.data.ownersList;
                const options = data.map((item) => ({
                    label: item.ownerId + " - " + item.name,
                    value: item.id,
                }));
                resolve(options);
            }, 1000);

            setTimeoutId(id);
        });
    return (<>
        <div className="col-lg-12 col-md-12 col-sm-12 row rtl p0 backgroundWhiteSystem agentForm transormRotateZero" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
            {
                canDeleteItem &&
                <div className="removeRowButn" onClick={() => removeItem(index)}>
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </div>
            }
            <div className="col-lg-6 col-md-6 col-sm-12" >
                <label className="capatalize_text">
                    {translate("web.chooseOwner", "Choose Owner")}
                    <i style={{ color: "red" }}>*</i>
                </label>
                <AsyncSelect
                    isClearable
                    loadOptions={promiseOptions}
                    defaultOptions={false} // Set to true to load initial options
                    onInputChange={(newValue) => setInputValue(newValue)}
                    inputValue={inputValue}
                    loadingMessage={() => translate("web.loading", "Loading")}
                    onChange={(selectedItem) => {
                        setSelectedOwner(selectedItem);
                        updatePermissionClaim(index, { ...claim, selectedOwner: selectedItem })
                    }}
                    placeholder={translate("web.typeToSearch", "Type To Search") + "..."}
                    noOptionsMessage={() => translate("web.noOptions", "No Options")}
                />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <NetaqInput
                    type={"select"}
                    label={translate("web.chooseRequestedPermisions", "Choose Requested Permisions")}
                    placeholder={translate("web.chooseRequestedPermisions", "Choose Requested Permisions")}
                    name={`premisions${index}`}
                    listSelect={[selectAllOption, ...permissionsList]}
                    value={selectedPermissions}
                    isMulti={true}
                    onChange={(selectedItem) => {
                        // console.log('selectred' , selectedItem);
                        let checkifAllSelected = false;
                        if (selectedItem.filter(item => item.value == 'all').length > 0) {
                            checkifAllSelected = true;
                        } else {
                            checkifAllSelected = false
                        }

                        if (checkifAllSelected) {
                            // Select all options
                            setSelectedPermissions(permissionsList);
                            updatePermissionClaim(index, { ...claim, selectedPermissions: permissionsList })
                        } else if (selectedItem.length === selectedPermissions.length - 1 && !selectedItem.includes(selectAllOption)) {
                            // Deselect all options
                            setSelectedPermissions([]);
                            updatePermissionClaim(index, { ...claim, selectedPermissions: [] });
                        } else {
                            // Update selected options without "Select All"
                            setSelectedPermissions(selectedItem.filter(option => option.value !== 'all'));
                            updatePermissionClaim(index, { ...claim, selectedPermissions: selectedItem.filter(option => option.value !== 'all') });
                        }
                        // setSelectedPermissions(selectedItem);
                        // updatePermissionClaim(index, { ...claim, selectedPermissions: selectedItem })
                    }}
                    required={true}
                />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 row mt-3 text-center" style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
            }} >
                <div className="col-lg-6 col-md-6 col-sm-12 p0">
                    <UploadFile
                        text={translate('web.uploadAutharizationDocuments', 'Upload Autharization Documents')}
                        ismultiFile={true}
                        name={`authfile${index}`}
                        className="text-center"
                        onChange={(e) => verifyPoAFileSettings(e)}
                    />
                </div>
                {!!agentAuthFiles && agentAuthFiles.map((fileItem, fileIndex) => (
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ margin: '10px auto' }} key={fileIndex}>
                        <Link
                            to={fileItem.documentFile}
                            className="C89C66 flex-center cursorPointer mb-25"
                            target="_blank"
                        >
                            <i className="fa fa-file fa-3x"></i>
                            <span className="black-color fontsize18 marginrightleft-10">
                                {extractChars(fileItem.name)}
                            </span>
                        </Link>
                    </div>
                ))}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-3 row p0">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h4>{translate('web.authDocumentsDate', 'Auth Documents Date')}: </h4>
                </div>
                <div className={isInOwnerProfile ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-3 col-md-6 col-sm-12"}>
                    {/* <NetaqInput
                        type={"date"}
                        label={translate('web.from', "From")}
                        name={`autharizationDocumentsStartDate${index + 1}`}
                        onChange={(event) => {
                            setFromDate(event);
                            updatePermissionClaim(index, { ...claim, fromDate: event })

                        }}
                        value={fromDate}
                    /> */}
                    <NetaqInput
                        type={'normalDate'}
                        label={translate('web.from', "From")}
                        name={`autharizationDocumentsStartDate${index + 1}`}
                        value={fromDate}
                        onChange={(e) => {
                            setFromDate(e.target.value);
                            updatePermissionClaim(index, { ...claim, fromDate: e.target.value })
                        }}
                        required={true}
                    />
                </div>
                <div className={isInOwnerProfile ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-3 col-md-6 col-sm-12"}>
                    {/* <NetaqInput
                        type={"date"}
                        label={translate('web.to', "To")}
                        name={`autharizationDocumentsExpiryDate${index + 1}`}
                        value={toDate}
                        onChange={(event) => {
                            setToDate(event);
                            updatePermissionClaim(index, { ...claim, toDate: event })
                        }}
                        minDate={fromDate}
                    /> */}
                     <NetaqInput
                        type={'normalDate'}
                        label={translate('web.to', "To")}
                        name={`autharizationDocumentsExpiryDate${index + 1}`}
                        value={toDate}
                        onChange={(event) => {
                            setToDate(event.target.value);
                            updatePermissionClaim(index, { ...claim, toDate: event.target.value })
                        }}
                        required={true}
                    />
                </div>
            </div>
            {
                !claim.isValid && Array.isArray(claim.errors) &&
                <>
                    <h4 className="col-lg-12 col-md-12 col-sm-12 text-center text-danger  rtl">
                        {translate('web.pleaseFillAllFields', 'Please Fill All Fields')}
                    </h4>
                    <div className="col-lg-12 col-md-12 col-sm-12 text-center text-danger  rtl">
                        {
                            claim.errors.map((error, i) => (`${translate(`web.${error}`, error)} ${(i + 1 < claim.errors.length) ? " - " : ''}`))
                        }
                    </div>
                </>
            }
        </div>
    </>)
}

export default GrantedPermissionClaimsComponent;