import React, { useEffect, useRef, useState } from "react";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import SubmitBtn from "../components/SubmitBtn";
import NetaqInput from "../components/NetaqInput";
import FileUpload from "../components/FileUpload";
import ActionButton from "../components/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchChangePasswordthunk,
  FetchCheckUserHasOwnerProfilethunk,
  FetchCitiesthunk,
  FetchCreateHorseMarketthunk,
  FetchDeleteHorseMarketthunk,
  FetchHorseDataByRegisterNumberthunk,
  FetchHorsePassportthunk,
  FetchOwnerAppuserByIdthunk,
  FetchOwnerByIdthunk,
  FetchOwnerHorseMarketthunk,
  FetchOwnerHorsesDropDownthunk,
  FetchOwnerHorsesthunk,
  FetchUpdateAccountthunk,
  FetchUpdateHorseMarketthunk,
  FetchUpdateOwnerAppuserAccountthunk,
} from "../Redux/OwnerProfile/OwnerProfileAction";
import {
  emptyPage,
  incrementPage,
  resetSearch,
  resetToasterMessage,
} from "../Redux/OwnerProfile/OwnerProfileSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";

import { useTranslate } from "../components/TranslationText";

import Loader from "../components/Loader";
import Select from "react-select";
import HorseCard from "../components/HorseCard";

import {
  emptyPageShowEntry,
  handelActiveTabOwnerProfile,
  handelResetShowEntryTempData,
  incrementPageShowEntry,
  resetSearchShowEntry,
} from "../Redux/ShowEntry/ShowEntrySlice";
import {
  FetchShowEntrythunk,
  FetchSubOwnerProfilesByIdthunk,
} from "../Redux/ShowEntry/ShowEntryAction";
import { CompareDate } from "../helper/CompareDate";
import { formatDate } from "../helper/formatDate";
import {
  FetchAuctionEntrythunk,
  FetchCheckOwnerHaseHorseInAuctionEntrythunk,
} from "../Redux/AuctionEntry/AuctionEntryAction";
import UploadFile from "../components/UploadFile";
import ComingSoon from "../components/ComingSoon";
import UpgradeProfile from "../components/UpgradeProfile";
import { convertToCamelCase } from "../helper/convertToCamelCase";
import OwnerPermisions from "../components/OwnerPermisions";
import {
  FetchDeleteOwnerShipTransferServicesRequeststhunk,
  FetchGetAllServicesRequeststhunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import { resetToasterMessageServicesRequests } from "../Redux/ServicesRequests/ServicesRequestsSlice";
import PaymentHistory from "../components/PaymentHistory";
import OrginizingRequests from "../components/OrginizingRequests";
import ParticipateHistory from "../components/ParticipateHistory";

export default function OwnerProfile() {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const [addNewHorse, setAddNewHorse] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedSearchType, setSelectedSearchType] = useState("-1");
  const userIsUAEPass = localStorage.getItem("EAHSUserIsUAEPASSUser");
  const [userProfile, setUserProfile] = useState("null");
  const [searchShow, setSearchShow] = useState("");
  const [searchServices, setSearchServices] = useState("");
  const [searchAuction, setSearchAuction] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [serviceRequestId, setServiceRequestId] = useState("");

  const loadDataFromLocal = () => {
    const jsonString = localStorage.getItem("eahsUserProfile");
    if (jsonString) {
      const data = JSON.parse(jsonString);
      setUserProfile(data);
      setSubOwnerProfiles(data.subAccounts);
    }
  };

  useEffect(() => {
    // Function to retrieve JSON data from local storage

    // Call the function to load data when the component mounts
    loadDataFromLocal();
  }, []);
  // console.log("useeeeeeeeeer UAE pass is", userIsUAEPass);

  //Form Account Settings
  const [englishName, setEnglishName] = useState("");
  const [arabicName, setArabicName] = useState("");
  const [email, setEmail] = useState("");

  const [secondEmail, setSecondEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [ownerPhoneNo, setOwnerPhoneNo] = useState("");
  const [smsPhoneNo, setSmsPhoneNo] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [subOwnerProfiles, setSubOwnerProfiles] = useState([]);

  const [signature, setSignature] = useState(null);
  const [emailNotValidMsg, setEmailNotValidMsg] = useState("classnotdisplay");
  const [urlVideoNotValidMsg, setUrlVideoNotValidMsg] =
    useState("classnotdisplay");

  const {
    ownerHorsesData,
    ownerHorsesDropDownData,
    ownerProfileLoading,
    ownerProfileError,
    stateShowMoreButtonHorse,
    page,
    take,
    totalCountOwnerHorses,
    ownerDetailsData,
    citiesData,
    horseDataByRegisterNumberData,
    ownerHorseMarketData,

    detailedResponseCode,
    responseCode,
    toasterMessage,
    ownerAppuserByIdData,
  } = useSelector((state) => state.ownerProfileSlice);

  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const {
    servicesRequestsLoading,
    getAllServicesRequestsData,
    toasterMessageServicesRequests,
  } = useSelector((state) => state.servicesRequestsSlice);
  const {
    pageShowEntry,
    takeShowEntry,
    showEntryData,
    showEntryLoading,
    totalCountShowEntry,
  } = useSelector((state) => state.showEntrySlice);
  const { auctionEntryData, auctionEntryLoading, auctionEntryError } =
    useSelector((state) => state.auctionEntrySlice);

  //Location
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const _activeTab = searchParams.has("activeTab")
    ? searchParams.get("activeTab")
    : null;

  const [activeTab, setActiveTab] = useState(
    _activeTab != null ? _activeTab : "accountSetting"
  );

  const { translate } = useTranslate();

  const [profile, setProfile] = useState({
    label:
      currentLanguage == "en"
        ? JSON.parse(localStorage.getItem("eahsUserProfile")).ownerRegisterNo +
        " - " +
        JSON.parse(localStorage.getItem("eahsUserProfile")).fullName
        : JSON.parse(localStorage.getItem("eahsUserProfile")).ownerRegisterNo +
        " - " +
        JSON.parse(localStorage.getItem("eahsUserProfile")).fullNameAr,
    value: localStorage.getItem("EAHSUserID"),
    text:
      currentLanguage == "en"
        ? JSON.parse(localStorage.getItem("eahsUserProfile")).ownerRegisterNo +
        " - " +
        JSON.parse(localStorage.getItem("eahsUserProfile")).fullName
        : JSON.parse(localStorage.getItem("eahsUserProfile")).ownerRegisterNo +
        " - " +
        JSON.parse(localStorage.getItem("eahsUserProfile")).fullNameAr,
  });

  const [subAccountsLoaded, setSubAccountsLoaded] = useState(false);

  useEffect(() => {
    if (subAccountsLoaded == false && userProfile.accountType == "Agent") {
      if (subOwnerProfiles.length > 0) {
        setProfile({
          label: subOwnerProfiles[0].text,
          value: subOwnerProfiles[0].value,
          text: subOwnerProfiles[0].text,
        });
        setSubAccountsLoaded(true);
      }
    }
  }, [subOwnerProfiles]);

  const dispatch = useDispatch();

  const [hovered, setHovered] = useState({
    sign: false,
    close: false,
    clear: false,
    save: false,
    secondsignature: false,
  });

  const signatureCanvas = useRef(null);
  const [imageURL, setImageURL] = useState(null);
  const [urlImages, setUrlImages] = useState([]);

  const [newHorseModel, setNewHorseModel] = useState({
    id: "",
    selectDrop: {
      label: translate("web.registerationNumber", "Registeration Number"),
      value: "",
    },
    regNo: "",
    nameEn: "",
    nameAr: "",
    category: "",
    categoryDrop: {
      label: translate("web.category", "category"),
      value: "",
    },
    price: "",
    city: "",
    cityDrop: {
      label: translate("web.emirate", "Emirate"),
      value: "",
    },
    address: "",
    phone: "",
    email: "",
    urlVideo: "",
    horseId: "",
    type: "",
    typeDrop: {
      label: translate("web.type", "type"),
      value: "",
    },
    isSold: false,
  });

  const [files, setFiles] = useState([]);
  const [emptyFiles, setEmptyFiles] = useState(true);
  const [showErrorConfirmPassword, setShowErrorConfirmPassword] =
    useState("none");

  const closeModal = () => setOpenPopup(false);

  const handleMouseOver = (type) => {
    setHovered((prev) => ({ ...prev, [type]: true }));
  };

  const handleMouseOut = (type) => {
    setHovered((prev) => ({ ...prev, [type]: false }));
  };

  const generateButtonStyle = (isHovered) => ({
    background: !isHovered ? "#ffffff" : "#E44D52",
    padding: "10px 20px",
    color: !isHovered ? "#E44D52" : "#fff",
    outline: 0,
    border: "solid",
    borderWidth: 1,
    borderColor: "#E44D52",
    transition: "all 0.3s",
    display: "block",
    cursor: "pointer",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "bold",
  });

  const buttonSubmitStyle = generateButtonStyle(hovered.sign);
  const buttonclearStyle = generateButtonStyle(hovered.clear);
  const buttoncloseStyle = generateButtonStyle(hovered.close);

  const onSaveSignature = (onCloseSignaturePad) => {
    if (!signatureCanvas.current?.isEmpty()) {
      const imageData = signatureCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setImageURL(imageData);
      onCloseSignaturePad();
    }
  };

  const onClearSignature = () => signatureCanvas.current.clear();

  useEffect(() => {
    dispatch(FetchOwnerAppuserByIdthunk(localStorage.getItem("EAHSUserID")));
    let params = {
      ownerId: localStorage.getItem("currentSubAccountId"),
      skip: 0,
      take,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: searchValue,
      sortBy: selectedSearchType,
    };
    dispatch(FetchOwnerHorsesthunk(params));
  }, []);

  useEffect(() => {
    dispatch(handelActiveTabOwnerProfile("allRegisteredHorses"));
    dispatch(handelResetShowEntryTempData());
    if (
      activeTab === "allRegisteredHorses" ||
      activeTab === "exported" ||
      activeTab === "dead" ||
      activeTab === "inactive"
    ) {
      if (page === 0) {
        dispatch(resetSearch());
        return;
      }
      let horse_type_filter;

      if (activeTab === "allRegisteredHorses") {
        horse_type_filter = "1";
      } else if (activeTab === "exported") {
        horse_type_filter = "2";
      } else if (activeTab === "dead") {
        horse_type_filter = "3";
      } else if (activeTab === "inactive") {
        horse_type_filter = "4";
      }
      if (userProfile.accountType != "BasicAccount") {
        let params = {
          ownerId: localStorage.getItem("currentSubAccountId"),
          skip: (page - 1) * take,
          take,
          langauge: currentLanguage,
          horseTypeFilter: horse_type_filter,
          searchValue: searchValue,
          sortBy: selectedSearchType,
        };
        dispatch(FetchOwnerHorsesthunk(params));
      }
    } else if (activeTab === "accountSetting") {
      let params = {
        ownerId: localStorage.getItem("currentSubAccountId"),
        skip: 0,
        take,
        langauge: currentLanguage,
        horseTypeFilter: "1",
        searchValue: searchValue,
        sortBy: selectedSearchType,
      };
      dispatch(FetchOwnerHorsesthunk(params));

      dispatch(FetchOwnerAppuserByIdthunk(localStorage.getItem("EAHSUserID")));
    } else if (activeTab === "horseMarket") {
      handleHorseMarketReset();
      setAddNewHorse(false);
      dispatch(FetchCitiesthunk());
      dispatch(
        FetchOwnerHorseMarketthunk(localStorage.getItem("currentSubAccountId"))
      );
      let params = {
        ownerId: localStorage.getItem("currentSubAccountId"),
        skip: 0,
        take: 1000,
        langauge: currentLanguage,
        horseTypeFilter: "1",
        searchValue: "",
        sortBy: "-1",
      };
      dispatch(FetchOwnerHorsesDropDownthunk(params));
    } else if (activeTab === "showEntry") {
      if (pageShowEntry === 0) {
        dispatch(resetSearchShowEntry());
        return;
      }
      if (userProfile.accountType != "BasicAccount") {
        let params = {
          ownerId: localStorage.getItem("currentSubAccountId"),
          skip: (pageShowEntry - 1) * takeShowEntry,
          take: takeShowEntry,
          langauge: currentLanguage,
        };
        dispatch(FetchShowEntrythunk(params));
      }
    } else if (activeTab === "auctionEntry") {
      let params = {
        langauge: currentLanguage,
        ownerId: localStorage.getItem("currentSubAccountId"),
      };
      dispatch(FetchAuctionEntrythunk(params));
    } else if (activeTab === "e_services") {
      if (userProfile.accountType != "BasicAccount") {
        let params = {
          language: currentLanguage,
          userId: localStorage.getItem("currentSubAccountId"),
        };
        dispatch(FetchGetAllServicesRequeststhunk(params));
      }
    }
  }, [page, pageShowEntry, currentLanguage, activeTab, smsPhoneNo]);

  const handelShowMore = async () => {
    dispatch(incrementPage());
  };

  const inputRef = useRef(null);
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleNewHorseChange = (e, input) => {
    if (input === "regNo") {
      dispatch(FetchHorseDataByRegisterNumberthunk(e.value));
      regNoRef.current = e.value;
      setNewHorseModel({
        ...newHorseModel,
        selectDrop: e,
      });
    } else if (input === "category") {
      setNewHorseModel({
        ...newHorseModel,
        [input]: e.value,
        categoryDrop: e,
      });
    } else if (input === "city") {
      setNewHorseModel({
        ...newHorseModel,
        [input]: e.value,
        cityDrop: e,
      });
    } else if (input === "type") {
      setNewHorseModel({
        ...newHorseModel,
        [input]: e.value,
        typeDrop: e,
      });
    } else if (input === "isSold") {
      setNewHorseModel({
        ...newHorseModel,
        [input]: e.target.checked,
      });
    } else {
      setNewHorseModel({
        ...newHorseModel,
        [input]: e.target.value,
      });
    }
  };

  const regNoRef = useRef("");
  useEffect(() => {
    if (Object.keys(horseDataByRegisterNumberData).length !== 0) {
      setNewHorseModel({
        ...newHorseModel,
        regNo: regNoRef.current,
        horseId: horseDataByRegisterNumberData.id,
        nameAr: horseDataByRegisterNumberData.nameAr,
        nameEn: horseDataByRegisterNumberData.nameEn,
        address:
          currentLanguage === "en"
            ? horseDataByRegisterNumberData.address
            : horseDataByRegisterNumberData.addressAr,
      });
    }
  }, [horseDataByRegisterNumberData]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    // dispatch(resetSearch());
  };

  const handleSelectedSearchType = (e) => {
    setSelectedSearchType(e.target.value);
    // dispatch(resetSearch());
  };

  const handelSearch = async () => {
    dispatch(emptyPage());
  };

  const handleFileChange = (e) => {
    let checkFileSize = true;
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        checkFileSize = false;
      } else {
        if (e.target.files && e.target.files.length > 0) {
          setSignature(e.target.files[0]);

          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function () {
            setImageURL(reader.result);
          };
        }
      }
    }

    if (!checkFileSize) {
      toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
    }
  };

  const handelUpdateOwnerAppuserAccount = async () => {
    let params = {
      id: localStorage.getItem("EAHSUserID"),
      secondMobileNo:
        mobileNo === "" ? ownerAppuserByIdData.secondMobileNo : mobileNo,
      phoneNo:
        ownerPhoneNo === "" ? ownerAppuserByIdData.phoneNo : ownerPhoneNo,
      secondEmail:
        secondEmail === "" ? ownerAppuserByIdData.secondEmail : secondEmail,

      email: email === "" ? ownerAppuserByIdData.email : email,
      mobileNo: phoneNo === "" ? ownerAppuserByIdData.mobileNo : phoneNo,

      signature: imageURL !== null ? imageURL : ownerAppuserByIdData.signature,
      tableType:
        userProfile.accountType != "BasicAccount" && userIsUAEPass === "false"
          ? "AppUserAndOwner"
          : userProfile.accountType != "BasicAccount" &&
            userIsUAEPass === "true"
            ? "Owner"
            : !userProfile.accountType != "BasicAccount" &&
              userIsUAEPass === "false"
              ? "AppUser"
              : "",
    };

    dispatch(FetchUpdateOwnerAppuserAccountthunk(params));
  };

  const handelChangePassword = async (e) => {
    e.preventDefault();
    if (confirmPassword === confirmNewPassword) {
      let params = {
        userId: localStorage.getItem("EAHSUserID"),
        //userId: "5FE062BC-4B73-4A7B-B498-017CD4BA2CFC",
        currentPassword: oldPassword,
        newPassword: confirmNewPassword,
      };
      setShowErrorConfirmPassword("none");

      dispatch(FetchChangePasswordthunk(params));
    } else {
      setShowErrorConfirmPassword("block");
    }
  };

  const handleHorseMarketReset = () => {
    setNewHorseModel({
      ...newHorseModel,
      id: "",
      regNo: "",
      selectDrop: {
        label: translate("web.registerationNumber", "Registeration Number"),
        value: "",
      },
      nameEn: "",
      nameAr: "",
      category: "",
      price: "",
      city: "",
      address: "",
      phone: ownerDetailsData.phone,
      email: ownerDetailsData.email,
      urlVideo: "",
      horseId: "",
      type: "",
      isSold: false,
    });

    setFiles([]);
    setUrlImages([]);
    setEmptyFiles(false);
    regNoRef.current = "";
  };

  function validateEmail(email) {
    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    return pattern.test(newHorseModel.email);
  }

  function validUrl(urlVideo) {
    const validate = urlVideo.includes("http") || urlVideo.includes("https");
    // const regex =
    //   /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
    return validate;
  }

  const handelNewHorse = async () => {
    if (validateEmail(newHorseModel.email) === false) {
      setEmailNotValidMsg("classdisplay");
      return;
    }

    if (
      newHorseModel.urlVideo !== "" &&
      validUrl(newHorseModel.urlVideo) === false
    ) {
      setUrlVideoNotValidMsg("classdisplay");
      return;
    }

    if (
      ownerHorseMarketData.find(
        (e) => e.horseRegisterNumber === newHorseModel.regNo
      ) &&
      newHorseModel.id === ""
    ) {
      toast.error(
        translate("web.cannotaddhorse", "This horse has been added before")
      );
      return;
    }

    setEmailNotValidMsg("classnotdisplay");
    setUrlVideoNotValidMsg("classnotdisplay");
    if (newHorseModel.id === "") {
      let params = {
        ownerId: localStorage.getItem("currentSubAccountId"),
        //ownerId: "3a5f8ec0-c888-4b2b-4c0c-08dc12faba88",
        horseId: newHorseModel.horseId,
        nameAr: newHorseModel.nameAr,
        nameEn: newHorseModel.nameEn,
        regNo: newHorseModel.regNo,
        category: newHorseModel.category,
        price: newHorseModel.price,
        city: newHorseModel.city,
        address: newHorseModel.address,
        phone: newHorseModel.phone,
        email: newHorseModel.email,
        urlVideo: newHorseModel.urlVideo,
        type: newHorseModel.type,
        files: files,
        //files: signature,
      };

      dispatch(FetchCreateHorseMarketthunk(params));
    } else {
      let imageListObjectsFirst = files.map((x) => ({
        id: "",
        image: x,
        url: "",
      }));
      let imageListObjectsSecond = urlImages.map((x) => ({
        id: "",
        image: null,
        url: x.substring(x.lastIndexOf("/") + 1),
      }));
      let imageListObjects = [
        ...imageListObjectsFirst,
        ...imageListObjectsSecond,
      ];

      let params = {
        horseMarket: {
          id: newHorseModel.id,
          englishName: newHorseModel.nameEn,
          arabicName: newHorseModel.nameAr,
          horsecategory: newHorseModel.category,
          horsePrice: newHorseModel.price,
          cityId: newHorseModel.city,
          address: newHorseModel.address,
          contact: newHorseModel.phone,
          email: newHorseModel.email,
          youTubeVideoUrl: newHorseModel.urlVideo,
          marketType: newHorseModel.type,
          isActive: null,
          isApproved: null,
          isSold: newHorseModel.isSold,
        },
        imageListObjects,
      };

      dispatch(FetchUpdateHorseMarketthunk(params));
    }
    if (!ownerProfileLoading && ownerProfileError === null) {
      handleHorseMarketReset();
      dispatch(
        FetchOwnerHorseMarketthunk(localStorage.getItem("currentSubAccountId"))
      );
    }
  };

  useEffect(() => {
    if (toasterMessage != null && toasterMessage === "save") {
      toast.success(
        translate("web.dataSavedSuccessfully", "Data Saved Successfully")
      );
      dispatch(resetToasterMessage());
    } else if (toasterMessage != null && toasterMessage === "delete") {
      toast.success(translate("web.deleted", "Deleted Success"));
      dispatch(resetToasterMessage());
    } else if (toasterMessage != null && toasterMessage === "update") {
      toast.success(
        translate("web.dataUpdatedSuccessfully", "Data Updated Successfully")
      );
      dispatch(resetToasterMessage());
    } else if (
      toasterMessage != null &&
      toasterMessage === "changepassword" &&
      responseCode === "200"
    ) {
      toast.success(
        translate(
          `web.${convertToCamelCase(detailedResponseCode)}`,
          detailedResponseCode
        )
      );
      dispatch(resetToasterMessage());
    } else if (
      toasterMessage != null &&
      toasterMessage === "changepassword" &&
      responseCode === "100"
    ) {
      toast.error(
        translate(
          `web.${convertToCamelCase(detailedResponseCode)}`,
          detailedResponseCode
        )
      );
      dispatch(resetToasterMessage());
    }
  }, [toasterMessage]);

  const dismissAll = () => toast.dismiss();

  const handelUpdateHorseMarket = (horseMarket) => {
    setAddNewHorse(true);
    setNewHorseModel({
      id: horseMarket.id,
      regNo: horseMarket.horseRegisterNumber,
      selectDrop: {
        label:
          horseMarket.horseRegisterNumber +
          " - " +
          (currentLanguage === "en"
            ? horseMarket.englisName
            : horseMarket.arabicName),
        value: horseMarket.horseRegisterNumber,
      },
      nameEn: horseMarket.englisName,
      nameAr: horseMarket.arabicName,
      category:
        horseMarket.horseCategory === "ShowHorse"
          ? "1"
          : horseMarket.horseCategory === "FlatRace"
            ? "2"
            : horseMarket.horseCategory === "EnduranceHorse"
              ? "3"
              : "",
      price: horseMarket.horsePrice,
      city: horseMarket.cityId,
      address: horseMarket.address,
      phone: horseMarket.phone,
      email: horseMarket.email,
      urlVideo: horseMarket.urlVideo,
      horseId: horseMarket.horseId,
      type: horseMarket.type,
      isSold: horseMarket.isSold,
    });

    let newArray = horseMarket.horseMarketMedia.map((x) => x.url);
    setUrlImages(newArray);
  };

  const handelDeleteServiceRequest = (id) => {
    dispatch(FetchDeleteOwnerShipTransferServicesRequeststhunk(id));
  };

  useEffect(() => {
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      toast.success(
        translate("web.dataSavedSuccessfully", "Data Saved Successfully")
      );
      dispatch(resetToasterMessageServicesRequests());

      dispatch(FetchGetAllServicesRequeststhunk(currentLanguage));
    }
  }, [toasterMessageServicesRequests]);

  const appUserProfileString = localStorage.getItem("eahsUserProfile") ?? "";

  let appUserProfile = "";
  if (!!appUserProfileString) {
    appUserProfile = JSON.parse(appUserProfileString);
  }

  useEffect(() => {
    localStorage.setItem("currentSubAccountId", profile.value);
    localStorage.setItem("currentSubAccountLabel", profile.label);
  }, [profile]);

  const handleSelectAccountChange = (e) => {
    setProfile(e);
    let params = {
      ownerId: e.value,
      skip: 0,
      take,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: searchValue,
      sortBy: selectedSearchType,
    };
    dispatch(FetchOwnerHorsesthunk(params));
    setActiveTab("");
    setActiveTab("allRegisteredHorses");
  };

  return (
    <div className="container-fluid container-fluid-padding">
      <div
        className={`page-overlay text-center ${ownerProfileLoading ||
          showEntryLoading ||
          auctionEntryLoading ||
          servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {userProfile?.status?.includes("Pending") && (
        <div style={{ display: "flex" }}>
          <div className="upgradeRequestFlage">
            {translate(`web.${userProfile?.status}`, userProfile?.status)}
            {" - "}
            {translate(
              `web.${convertToCamelCase(userProfile.upgradeStatus)}`,
              userProfile.upgradeStatus
            )}
          </div>
        </div>
      )}

      {
        <React.Fragment>
          <div
            className="row rtl justify-content-between"
            style={{ margin: 0 }}
          >
            <>
              {!!ownerDetailsData && (
                <React.Fragment>
                  <div className="col-lg-6 col-md-6 col-sm-12 rtl">
                    <h2 className="ownerProfileName">
                      {!!ownerDetailsData.ownerId &&
                        ownerDetailsData.id == userProfile.id
                        ? ownerDetailsData.ownerId +
                        " - " +
                        (currentLanguage == "en"
                          ? userProfile.fullName
                          : userProfile.fullNameAr)
                        : !!ownerDetailsData.ownerId &&
                        ownerDetailsData.ownerId +
                        " - " +
                        ownerDetailsData.name}
                    </h2>
                    <span className="ownerAddress">
                      {ownerDetailsData.nationality}
                    </span>
                    {!!ownerDetailsData?.numberofHorsesOwned &&
                      ownerDetailsData?.numberofHorsesOwned > 0 && (
                        <span className="ownerHorseCount">
                          {translate("web.owns", "Owns")}{" "}
                          {ownerDetailsData.numberofHorsesOwned}{" "}
                          {translate("web.horses", "Horses")}
                        </span>
                      )}
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-12 align-self-end mt-20">
                    {!!subOwnerProfiles && subOwnerProfiles.length > 0 && (
                      <React.Fragment>
                        <label className="capatalize_text">
                          {translate("web.selectedAccount", "Selected Account")}
                        </label>
                        <Select
                          options={
                            userProfile.accountType != "Agent"
                              ? [
                                {
                                  label:
                                    userProfile.ownerRegisterNo +
                                    " - " +
                                    userProfile[
                                    `fullName${currentLanguage == "en" ? "" : "Ar"
                                    }`
                                    ] ?? "",
                                  value: appUserProfile.id ?? "",
                                },
                                ...(!!subOwnerProfiles &&
                                  subOwnerProfiles.map((a) => ({
                                    label:
                                      a.registerNo +
                                      " - " +
                                      a[
                                      `text${currentLanguage == "en" ? "" : "Ar"
                                      }`
                                      ],
                                    value: a.value,
                                  }))),
                              ]
                              : [
                                ...(!!subOwnerProfiles &&
                                  subOwnerProfiles.map((a) => ({
                                    label:
                                      a.registerNo +
                                      " - " +
                                      a[
                                      `text${currentLanguage == "en" ? "" : "Ar"
                                      }`
                                      ],
                                    value: a.value,
                                  }))),
                              ]
                          }
                          value={profile}
                          onChange={handleSelectAccountChange}
                          isSearchable
                          noOptionsMessage={() =>
                            translate("web.noOptions", "No Options")
                          }
                        />
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              )}
            </>
          </div>
          <br />
          <br />
          <div className="row rtl" style={{ margin: 0 }}>
            <div className="col-lg-3 col-md-4 col-sm-12 mobileRow rtl ">
              {(((userProfile.accountType == "LocalOwner" ||
                userProfile.accountType == null) &&
                (userProfile.status == null ||
                  !userProfile.status.includes("Pending"))) ||
                userProfile.accountType == "Agent") && (
                  <>
                    <div className="col-lg-12 col-md-5 col-sm-5">
                      <span
                        className="ownerProfileButton"
                        style={{
                          background:
                            activeTab === "allRegisteredHorses"
                              ? "#E44D52"
                              : "rgba(0,0,0,0.08)",
                          color:
                            activeTab === "allRegisteredHorses" ? "#fff" : "#000",
                        }}
                        onClick={() => {
                          dispatch(emptyPage());
                          setActiveTab("allRegisteredHorses");
                        }}
                      >
                        {translate(
                          "web.allRegisteredHorses",
                          "All Registered Horses"
                        )}
                      </span>
                    </div>
                    <div className="col-lg-12 col-md-5 col-sm-5">
                      <span
                        className="ownerProfileButton"
                        style={{
                          background:
                            activeTab === "exported"
                              ? "#E44D52"
                              : "rgba(0,0,0,0.08)",
                          color: activeTab === "exported" ? "#fff" : "#000",
                        }}
                        onClick={() => {
                          dispatch(emptyPage());
                          setActiveTab("exported");
                        }}
                      >
                        {translate("web.exported", "exported")}
                      </span>
                    </div>
                    <div className="col-lg-12 col-md-5 col-sm-5">
                      <span
                        className="ownerProfileButton"
                        style={{
                          background:
                            activeTab === "dead" ? "#E44D52" : "rgba(0,0,0,0.08)",
                          color: activeTab === "dead" ? "#fff" : "#000",
                        }}
                        onClick={() => {
                          dispatch(emptyPage());
                          setActiveTab("dead");
                        }}
                      >
                        {translate("web.dead", "Dead")}
                      </span>
                    </div>
                    <div className="col-lg-12 col-md-5 col-sm-5">
                      <span
                        className="ownerProfileButton"
                        style={{
                          background:
                            activeTab === "inactive"
                              ? "#E44D52"
                              : "rgba(0,0,0,0.08)",
                          color: activeTab === "inactive" ? "#fff" : "#000",
                        }}
                        onClick={() => {
                          dispatch(emptyPage());
                          setActiveTab("inactive");
                        }}
                      >
                        {translate("web.inactive", "Inactive")}
                      </span>
                    </div>
                  </>
                )}

              {(((userProfile.accountType == "LocalOwner" ||
                userProfile.accountType == null) &&
                (userProfile.status == null ||
                  !userProfile.status.includes("Pending"))) ||
                (userProfile.accountType == "Agent" &&
                  (subOwnerProfiles
                    .filter((x) => x.value == profile.value)[0]
                    ?.ownersPermissionsList.map((z) => z.permissionName)
                    .filter((z) => z == "HorseMarket").length > 0 ||
                    subOwnerProfiles
                      .filter((x) => x.value == profile.value)[0]
                      ?.ownersPermissionsList.map((z) => z.permissionName)
                      .includes("All")))) && (
                  <>
                    {/* <div className="col-lg-12 col-md-5 col-sm-5">
                      <span
                        className="ownerProfileButton"
                        style={{
                          background:
                            activeTab === "horseMarket"
                              ? "#E44D52"
                              : "rgba(0,0,0,0.08)",
                          color: activeTab === "horseMarket" ? "#fff" : "#000",
                        }}
                        onClick={() => {
                          setActiveTab("horseMarket");
                        }}
                      >
                        {translate("web.horseMarket", "Horse Market")}
                      </span>
                    </div> */}
                  </>
                )}
              {(((userProfile.accountType == "LocalOwner" ||
                userProfile.accountType == null) &&
                (userProfile.status == null ||
                  !userProfile.status.includes("Pending"))) ||
                userProfile.accountType == "InternationalOwner" ||
                (userProfile.accountType == "Agent" &&
                  (subOwnerProfiles
                    .filter((x) => x.value == profile.value)[0]
                    ?.ownersPermissionsList.map((z) => z.permissionName)
                    .filter((z) => z == "ShowEntry").length > 0 ||
                    subOwnerProfiles
                      .filter((x) => x.value == profile.value)[0]
                      ?.ownersPermissionsList.map((z) => z.permissionName)
                      .includes("All")))) && (
                  <>
                    <div className="col-lg-12 col-md-5 col-sm-5">
                      <span
                        className="ownerProfileButton"
                        style={{
                          background:
                            activeTab === "showEntry"
                              ? "#E44D52"
                              : "rgba(0,0,0,0.08)",
                          color: activeTab === "showEntry" ? "#fff" : "#000",
                        }}
                        onClick={() => {
                          dispatch(emptyPageShowEntry());
                          setActiveTab("showEntry");
                        }}
                      >
                        {translate("web.showEntry", "show Entry")}
                      </span>
                    </div>
                  </>
                )}
              {(((userProfile.accountType == "LocalOwner" ||
                userProfile.accountType == null) &&
                (userProfile.status == null ||
                  !userProfile.status.includes("Pending"))) ||
                (userProfile.accountType == "Agent" &&
                  (subOwnerProfiles
                    .filter((x) => x.value == profile.value)[0]
                    ?.ownersPermissionsList.map((z) => z.permissionName)
                    .filter((z) => z == "AuctionEntry").length > 0 ||
                    subOwnerProfiles
                      .filter((x) => x.value == profile.value)[0]
                      ?.ownersPermissionsList.map((z) => z.permissionName)
                      .includes("All")))) && (
                  <>
                    <div className="col-lg-12 col-md-5 col-sm-5">
                      <span
                        className="ownerProfileButton"
                        style={{
                          background:
                            activeTab === "auctionEntry"
                              ? "#E44D52"
                              : "rgba(0,0,0,0.08)",
                          color: activeTab === "auctionEntry" ? "#fff" : "#000",
                        }}
                        onClick={() => {
                          //dispatch(emptyPageAuctionEntry());
                          setActiveTab("auctionEntry");
                        }}
                      >
                        {translate("web.auctionEntry", "Auction Entry")}
                      </span>
                    </div>
                  </>
                )}

              {/* {userProfile.accountType != "Organizer" && (
                <div className="col-lg-12 col-md-5 col-sm-5">
                  <span
                    className="ownerProfileButton"
                    style={{
                      background:
                        activeTab === "e_services"
                          ? "#E44D52"
                          : "rgba(0,0,0,0.08)",
                      color: activeTab === "e_services" ? "#fff" : "#000",
                    }}
                    onClick={() => {
                      setActiveTab("e_services");
                    }}
                  >
                    {translate("web.services", "Services")}
                  </span>
                </div>
              )} */}
              {userProfile.accountType == "Organizer" && (
                <div className="col-lg-12 col-md-5 col-sm-5">
                  <span
                    className="ownerProfileButton"
                    style={{
                      background:
                        activeTab === "organizerRequests"
                          ? "#E44D52"
                          : "rgba(0,0,0,0.08)",
                      color:
                        activeTab === "organizerRequests" ? "#fff" : "#000",
                    }}
                    onClick={() => {
                      setActiveTab("organizerRequests");
                    }}
                  >
                    {translate("web.organizingRequests", "Organizing Requests")}
                  </span>
                </div>
              )}
              {userProfile.accountType != "Organizer" && (
                <>
                  <div className="col-lg-12 col-md-5 col-sm-5">
                    <span
                      className="ownerProfileButton"
                      style={{
                        background:
                          activeTab === "paymentHistory"
                            ? "#E44D52"
                            : "rgba(0,0,0,0.08)",
                        color: activeTab === "paymentHistory" ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        setActiveTab("paymentHistory");
                      }}
                    >
                      {translate("web.paymentHistory", "Payment History")}
                    </span>
                  </div>
                  <div className="col-lg-12 col-md-5 col-sm-5">
                    <span
                      className="ownerProfileButton"
                      style={{
                        background:
                          activeTab === "participateHistory"
                            ? "#E44D52"
                            : "rgba(0,0,0,0.08)",
                        color: activeTab === "participateHistory" ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        setActiveTab("participateHistory");
                      }}
                    >
                      {translate("web.participateHistory", "Participate History")}
                    </span>
                  </div>
                </>
              )}

              <div className="col-lg-12 col-md-5 col-sm-5">
                <span
                  className="ownerProfileButton"
                  style={{
                    background:
                      activeTab === "accountSetting"
                        ? "#E44D52"
                        : "rgba(0,0,0,0.08)",
                    color: activeTab === "accountSetting" ? "#fff" : "#000",
                  }}
                  onClick={() => {
                    setActiveTab("accountSetting");
                  }}
                >
                  {translate("web.accountSettings", "Account Settings")}
                </span>
              </div>
              {userProfile.accountType == "BasicAccount" && (
                <div className="col-lg-12 col-md-5 col-sm-5">
                  <span
                    className="ownerProfileButton"
                    style={{
                      background:
                        activeTab === "upgradeProfile"
                          ? "#E44D52"
                          : "rgba(0,0,0,0.08)",
                      color: activeTab === "upgradeProfile" ? "#fff" : "#000",
                    }}
                    onClick={() => setActiveTab("upgradeProfile")}
                  >
                    {translate("web.upgradeProfile", "Upgrade Profile")}
                  </span>
                </div>
              )}
              {userProfile.accountType == "Agent" && (
                <div className="col-lg-12 col-md-5 col-sm-5">
                  <span
                    className="ownerProfileButton"
                    style={{
                      background:
                        activeTab === "owenerPermisions"
                          ? "#E44D52"
                          : "rgba(0,0,0,0.08)",
                      color: activeTab === "owenerPermisions" ? "#fff" : "#000",
                    }}
                    onClick={() => setActiveTab("owenerPermisions")}
                  >
                    {translate("web.owenerPermisions", "Owener Permisions")}
                  </span>
                </div>
              )}
            </div>
            {/* Horse Cards (First Four Tabs) */}
            {(activeTab === "allRegisteredHorses" ||
              activeTab === "exported" ||
              activeTab === "dead" ||
              activeTab === "inactive") && (
                <div className="col-lg-9 col-md-8 col-sm-12  rtl">
                  <div className="row rtl">
                    <div className=" col-lg-12 col-md-12 col-sm-12 form-group ownerProfileInput ">
                      <i
                        className="fas fa-search fa-xl"
                        style={{
                          marginLeft: 14,
                          color: "rgb(244 159 162)",
                          marginRight: 14,
                          marginTop: 19,
                        }}
                      ></i>
                      <input
                        type="text"
                        className="form-control"
                        style={{ height: "38px", border: 0, outline: 0 }}
                        onChange={handleChange}
                      />
                      <select
                        className="ml-5 mr-5 orderbySelect form-control"
                        style={{
                          backgroundColor: "#f0f0f0",
                          borderColor: "white",
                          height: "38px",
                          borderRadius: 5,
                          textAlign: "center",
                          backgroundImage: `url('${BaseURL}/assets/img/down-arrow.png')`,
                          backgroundRepeat: "no-repeat",
                          backgroundPositionX: "92%",
                          backgroundPositionY: "51%",
                          WebkitAppearance: "none",
                        }}
                        defaultValue={selectedSearchType}
                        onChange={handleSelectedSearchType}
                      >
                        <option selected disabled value={"-1"}>
                          {translate("web.sortBy", "Sort By")}
                        </option>
                        <option value={"2"}>
                          {translate("web.highestRegNum", "Highest Reg Num")}
                        </option>
                        <option value={"1"}>
                          {translate("web.lowestRegNum", "Lowest Reg Num")}
                        </option>
                        <option value={"3"}>
                          {translate("web.atoz", "A-Z")}
                        </option>
                        <option value={"4"}>
                          {translate("web.ztoa", "Z-A")}
                        </option>
                        <option value={"5"}>
                          {translate("web.highestAge", "Highest Age")}
                        </option>
                        <option value={"6"}>
                          {translate("web.ageLowest", "Age Lowest")}
                        </option>
                      </select>
                      <button
                        className="btn btn-danger width-inherit"
                        onClick={handelSearch}
                      >
                        {translate("web.search", "Search")}
                      </button>
                    </div>

                    <span className="col-lg-12 col-md-12 col-sm-12 ownerProfileSpan rtl marginArabicspan">
                      {totalCountOwnerHorses}{" "}
                      {translate("web.resultsfound", "Results Found")}
                    </span>
                  </div>
                  <div className="row  rtl" style={{ marginTop: 23 }}>
                    {!!ownerHorsesData &&
                      ownerHorsesData.length > 0 &&
                      ownerHorsesData.map((item, i) => (
                        <HorseCard
                          isArabicClass={true}
                          item={item}
                          i={i}
                          showDownloadPassport={
                            activeTab === "allRegisteredHorses"
                          }
                        />
                      ))}
                    <div
                      className="col-lg-12 col-md-12 col-sm-12  text-center"
                      style={{ marginTop: 20 }}
                    >
                      {!ownerProfileLoading &&
                        !!ownerHorsesData &&
                        ownerHorsesData.length > 0 ? (
                        <SubmitBtn
                          marginBottom={50}
                          type={"loadmore"}
                          disabled={stateShowMoreButtonHorse}
                          onClick={handelShowMore}
                          label={`
                        ${translate("web.showMore", "Show More")} (${ownerHorsesData.length
                            } ${translate(
                              "web.of",
                              "of"
                            )} ${totalCountOwnerHorses})`}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            {
              //   activeTab === "exported" && (
              //   <div className="col-lg-9 col-md-9 col-sm-6">No Data Here</div>
              // )
            }

            {/* Account Setting Tab */}
            {activeTab === "accountSetting" && (
              <div className="col-lg-9 col-md-8 col-sm-12  rtl">
                <div className="row userinfocard rtl">
                  <div className="col-lg-12 col-md-12 col-sm-12  userInfoTitle">
                    {translate("web.userInfo", "user Info")}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12  ">
                    <NetaqInput
                      type={"text"}
                      name={"English Name"}
                      placeholder={translate("web.englishName", "English Name")}
                      label={translate("web.englishName", "English Name")}
                      value={
                        englishName !== ""
                          ? englishName
                          : !!ownerAppuserByIdData &&
                          ownerAppuserByIdData.fullName
                        //: !!OwnerByIdData && OwnerByIdData.ownerNameEn
                      }
                      disabled={true}
                      onChange={(e) => setEnglishName(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12  ">
                    <NetaqInput
                      type={"text"}
                      name={"Arabic Name"}
                      placeholder={translate("web.arabicName", "Arabic Name")}
                      label={translate("web.arabicName", "Arabic Name")}
                      value={
                        arabicName !== ""
                          ? arabicName
                          : !!ownerAppuserByIdData &&
                          ownerAppuserByIdData.fullNameAr
                        //: !!OwnerByIdData && OwnerByIdData.ownerNameAr
                      }
                      disabled={true}
                      onChange={(e) => setArabicName(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <NetaqInput
                      type={"email"}
                      name={"email"}
                      placeholder={translate("web.email", "Email")}
                      label={translate("web.email", "Email")}
                      value={
                        email !== ""
                          ? email
                          : !!ownerAppuserByIdData && ownerAppuserByIdData.email
                        //: !!OwnerByIdData && OwnerByIdData.email
                      }
                      disabled={userIsUAEPass === "false" ? false : true}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <NetaqInput
                      type={"text"}
                      name={"mobile no"}
                      placeholder={translate("web.smsPhoneNo", "Mobile No")}
                      label={translate("web.smsPhoneNo", "Mobile No")}
                      disabled={userIsUAEPass === "false" ? false : true}
                      //value={!!OwnerByIdData && OwnerByIdData.phone}
                      value={
                        phoneNo !== ""
                          ? phoneNo
                          : !!ownerAppuserByIdData &&
                          ownerAppuserByIdData.mobileNo
                        //: !!OwnerByIdData && OwnerByIdData.phone
                      }
                      onChange={(e) => setPhoneNo(e.target.value)}
                    />
                  </div>
                  {userProfile.accountType != "BasicAccount" && (
                    <React.Fragment>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          type={"email"}
                          name={"second email"}
                          placeholder={translate(
                            "web.secondEmail",
                            "Second Email"
                          )}
                          label={translate("web.secondEmail", "Second Email")}
                          value={
                            secondEmail !== ""
                              ? secondEmail
                              : !!ownerAppuserByIdData &&
                              ownerAppuserByIdData.secondEmail
                            //: !!OwnerByIdData && OwnerByIdData.email
                          }
                          onChange={(e) => setSecondEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          type={"text"}
                          name={"phone no"}
                          placeholder={translate("web.phoneNo", "Phone No")}
                          label={translate("web.phoneNo", "Phone No")}
                          value={
                            ownerPhoneNo !== ""
                              ? ownerPhoneNo
                              : !!ownerAppuserByIdData &&
                              ownerAppuserByIdData.phoneNo
                            //: !!OwnerByIdData && OwnerByIdData.phone
                          }
                          onChange={(e) => setOwnerPhoneNo(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          type={"text"}
                          name={"second mobile no"}
                          placeholder={translate(
                            "web.secondMobileNo",
                            "Second Mobile No"
                          )}
                          label={translate(
                            "web.secondMobileNo",
                            "Second Mobile No"
                          )}
                          value={
                            mobileNo !== ""
                              ? mobileNo
                              : !!ownerAppuserByIdData &&
                              ownerAppuserByIdData.secondMobileNo
                            //: !!OwnerByIdData && OwnerByIdData.phone
                          }
                          onChange={(e) => setMobileNo(e.target.value)}
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label>{translate("web.signature", "Signature")}</label>

                    <div
                      className="col-lg-12 col-md-12 col-sm-12"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 20,
                      }}
                    >
                      <span style={{ margin: "auto 5px" }}> </span>
                      <Popup
                        modal
                        lockScroll
                        trigger={
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <span
                              style={buttonSubmitStyle}
                              onMouseOver={() => handleMouseOver("sign")}
                              onMouseOut={() => handleMouseOut("sign")}
                              className="opensigniturepad"
                            >
                              {" "}
                              {translate(
                                "web.openSigniturePad",
                                "Open Signiture Pad"
                              )}{" "}
                            </span>
                          </div>
                        }
                        closeOnDocumentClick={false}
                        closeOnEscape
                      >
                        {(close) => (
                          <React.Fragment>
                            <SignaturePad
                              ref={signatureCanvas}
                              canvasProps={{
                                className: "signatureCanvas",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <button
                                style={buttonclearStyle}
                                onMouseOver={() => handleMouseOver("save")}
                                onMouseOut={() => handleMouseOut("save")}
                                onClick={() => {
                                  onSaveSignature(close);
                                }}
                              >
                                {translate("web.save", "Save")}{" "}
                              </button>
                              <button
                                style={buttonclearStyle}
                                onMouseOver={() => handleMouseOver("clear")}
                                onMouseOut={() => handleMouseOut("clear")}
                                onClick={onClearSignature}
                              >
                                {" "}
                                {translate("web.clear", "Clear")}{" "}
                              </button>
                              <button
                                style={buttoncloseStyle}
                                onMouseOver={() => handleMouseOver("close")}
                                onMouseOut={() => handleMouseOut("close")}
                                onClick={close}
                              >
                                {" "}
                                {translate("web.close", "close")}{" "}
                              </button>
                            </div>
                          </React.Fragment>
                        )}
                      </Popup>

                      <span className="uaepassor">
                        {translate("web.or", "OR")}
                      </span>
                      {
                        <React.Fragment>
                          <UploadFile
                            text="Upload Singniture Photo"
                            onChange={handleFileChange}
                          />
                        </React.Fragment>
                      }
                    </div>
                    <div
                      style={{
                        marginTop: 7, //width: 474
                        textAlign: "center",
                      }}
                      className="col-lg-12 col-md-12 col-sm-12"
                    >
                      <div className="text-center">
                        {imageURL ||
                          (!!ownerAppuserByIdData &&
                            !!ownerAppuserByIdData.signature) ? (
                          //(!!OwnerByIdData && !!OwnerByIdData.signature) ? (
                          <img
                            src={imageURL || ownerAppuserByIdData.signature}
                            //src={imageURL || OwnerByIdData.signature}
                            alt={translate("web.signature", "Signature")}
                            style={{
                              width: "100%",
                              maxWidth: 216,
                              maxHeight: 216,
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div
                    className="submitButtonContainerleft saveaccountInformation"
                    style={{ width: "100%" }}
                  >
                    {!userProfile.accountType != "BasicAccount" &&
                      userIsUAEPass === "true" ? (
                      ""
                    ) : (
                      <ActionButton
                        type={"submit"}
                        label={"Save"}
                        text={translate("web.save", "Save")}
                        margin={"0"}
                        onClick={handelUpdateOwnerAppuserAccount}
                        disabled={
                          (email !== "" || ownerAppuserByIdData.email !== "") &&
                            (phoneNo !== "" ||
                              ownerAppuserByIdData.mobileNo !== "") &&
                            (secondEmail !== "" ||
                              ownerAppuserByIdData.secondEmail !== "") &&
                            userProfile.accountType != "BasicAccount" &&
                            (ownerPhoneNo !== "" ||
                              ownerAppuserByIdData.phoneNo !== "") &&
                            userProfile.accountType != "BasicAccount" &&
                            (mobileNo !== "" ||
                              ownerAppuserByIdData.secondMobileNo !== "") &&
                            userProfile.accountType != "BasicAccount"
                            ? false
                            : true
                        }
                      />
                    )}
                  </div>
                </div>
                {userIsUAEPass === "false" && (
                  <form
                    className="row changepasswordcard rtl"
                    onSubmit={handelChangePassword}
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12 userInfoTitle">
                      {translate("web.changePassword", "Change Password")}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <NetaqInput
                        type={"password"}
                        name={"oldpassword"}
                        placeholder={translate(
                          "web.oldPassword",
                          "Old Password"
                        )}
                        label={translate("web.oldPassword", "Old Password")}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required={true}
                      />
                    </div>
                    {showErrorConfirmPassword != "none" && (
                      <div
                        className="col-lg-6 col-md-6 col-sm-12"
                        style={{ minHeight: 85, display: "flex" }}
                      >
                        <span
                          className="text-danger"
                          style={{
                            display: showErrorConfirmPassword,
                            margin: "auto",
                          }}
                        >
                          {translate(
                            "web.errorConfirmPassword",
                            "Error in Confirm Password"
                          )}
                        </span>
                      </div>
                    )}
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <NetaqInput
                        type={"password"}
                        name={"newpassword"}
                        placeholder={translate(
                          "web.newPassword",
                          "New Password"
                        )}
                        label={translate("web.newPassword", "New Password")}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <NetaqInput
                        type={"password"}
                        name={"confirmpassword"}
                        placeholder={translate(
                          "web.confirmPassword",
                          "Confirm Password"
                        )}
                        label={translate(
                          "web.confirmPassword",
                          "Confirm Password"
                        )}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required={true}
                      />
                    </div>

                    <div className="submitButtonContainerleft">
                      <ActionButton
                        type={"submit"}
                        label={"Save"}
                        text={translate("web.save", "Save")}
                        margin={"0"}
                      />
                    </div>
                  </form>
                )}
              </div>
            )}
            {/* horse Market */}
            {activeTab === "horseMarket" && (
              <div className="col-lg-9 col-md-8 col-sm-12 row rtl ">
                {!addNewHorse && (
                  <div className="col-lg-12 col-sm-12">
                    <span
                      className="AddNewHorseBtn"
                      onClick={() => setAddNewHorse(true)}
                    >
                      + {translate("web.addNewHorse", "Add New Horse")}
                    </span>
                  </div>
                )}
                {!addNewHorse && (
                  <React.Fragment>
                    {!!ownerHorseMarketData &&
                      ownerHorseMarketData.length > 0 &&
                      ownerHorseMarketData.map((item, i) => (
                        <div
                          className="col-lg-6 col-md-12 col-sm-12 "
                          style={{
                            padding: 0,
                            paddingRight: 5,
                            background: "#FCFAF7",
                            marginTop: 10,
                          }}
                          key={i}
                        >
                          <div
                            className="horseCardContainer row rtl"
                            style={{ display: "flex" }}
                          >
                            <div className="col-lg-4 col-md-4 col-sm-12 horse-market-card-div  ">
                              <span className="horseMarketCardCategory">
                                {item.horseCategory != null
                                  ? item.horseCategory === "ShowHorse"
                                    ? translate("web.showHorse", "show Horse")
                                    : item.horseCategory === "FlatRace"
                                      ? translate("web.flatRace", "Flat Race")
                                      : item.horseCategory === "EnduranceHorse"
                                        ? translate(
                                          "web.enduranceHorse",
                                          "Endurance Horse"
                                        )
                                        : item.horseCategory
                                  : item.horseCategory}
                              </span>
                              <div className="horseMarketImageContainer_OwnerProfile">
                                <img
                                  style={{ borderRadius: 5 }}
                                  src={
                                    item.horseMarketMedia.length > 0
                                      ? item.horseMarketMedia[0].url
                                      : `${BaseURL}/assets/img/horsemarket.jpg`
                                  }
                                  alt="itemName"
                                />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12  ">
                              <span className="horseMarketCardLabeltitle">
                                {currentLanguage === "en" ? (
                                  item.englisName !== null &&
                                    item.englisName !== "" ? (
                                    item.englisName
                                  ) : (
                                    <React.Fragment> &nbsp;</React.Fragment>
                                  )
                                ) : item.arabicName !== null &&
                                  item.arabicName !== "" ? (
                                  item.arabicName
                                ) : (
                                  <React.Fragment> &nbsp;</React.Fragment>
                                )}
                              </span>
                              <span
                                className="horseMarketCardLabeltitle rtlabs"
                                style={{
                                  position: "absolute",
                                  right: 10,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handelUpdateHorseMarket(item)}
                              >
                                <img
                                  alt="editicon"
                                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1lZGl0IiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMSA0SDRhMiAyIDAgMCAwLTIgMnYxNGEyIDIgMCAwIDAgMiAyaDE0YTIgMiAwIDAgMCAyLTJ2LTciLz48cGF0aCBkPSJNMTguNSAyLjVhMi4xMjEgMi4xMjEgMCAwIDEgMyAzTDEyIDE1bC00IDEgMS00IDkuNS05LjV6Ii8+PC9zdmc+"
                                />
                              </span>
                              <div className="row ">
                                <div
                                  className="col-md-12"
                                  style={{
                                    marginBottom: 5,
                                  }}
                                >
                                  <span className="horseCardlabelContent ">
                                    {item.birthDate &&
                                      formatDate(item.birthDate)}{" "}
                                    - {item.genderString}
                                    {(item.birthDate === null ||
                                      item.birthDate === "") && (
                                        <React.Fragment> &nbsp;</React.Fragment>
                                      )}
                                  </span>
                                  <span className="horseCardlabelContent ">
                                    {currentLanguage === "en" ? (
                                      item.originEn !== null &&
                                        item.originEn !== "" ? (
                                        item.originEn + "," + item.cityName
                                      ) : (
                                        <React.Fragment> &nbsp;</React.Fragment>
                                      )
                                    ) : item.originAr !== null &&
                                      item.originAr !== "" ? (
                                      item.originAr + "," + item.cityNameAr
                                    ) : (
                                      <React.Fragment> &nbsp;</React.Fragment>
                                    )}
                                  </span>
                                  {
                                    // <span className="horseCardlabelContent">
                                    //     {item.address !== null &&
                                    //     item.address !== "" ? (
                                    //       item.address
                                    //     ) : (
                                    //       <React.Fragment> &nbsp;</React.Fragment>
                                    //     )}
                                    //   </span>
                                  }
                                  <div className="row">
                                    <div className="col-sm-2 cardKeyColor ">
                                      {translate("web.sire", "Sire")}
                                    </div>
                                    <div className="col-sm-10 cardValueColor ">
                                      {currentLanguage === "en"
                                        ? item.sireNameEn
                                        : item.sireNameAr}
                                    </div>
                                    <div className="col-sm-2 cardKeyColor ">
                                      {translate("web.dam", "dam")}
                                    </div>
                                    <div className="col-sm-10 cardValueColor ">
                                      {currentLanguage === "en"
                                        ? item.damNameEn
                                        : item.damNameAr}
                                    </div>
                                    <div className="col-sm-3 cardKeyColor ">
                                      {translate("web.color", "color")}
                                    </div>
                                    <div className="col-sm-9 cardValueColor ">
                                      {currentLanguage === "en"
                                        ? item.colorEn
                                        : item.colorAr}
                                    </div>
                                  </div>
                                  <span className="horseCardLabeltitle ">
                                    {item.horsePrice}{" "}
                                    {currentLanguage === "en" ? "AED" : "د.إ"}
                                  </span>
                                </div>
                              </div>

                              {/* <Link
                                className="hoveronlink ViewProfileLink"
                                to={`/horsedetails?horseId=${item.horseId}`}
                              >
                                {translate("web.viewDetails", "View Details")}
                                <i
                                  className="fa-solid fa-chevron-right"
                                  style={{
                                    color: "#E44D52",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                  }}
                                ></i>
                              </Link> */}
                              <div className="row">
                                <Link
                                  to={`/horsedetails?horseId=${item.id}`}
                                  className="hoveronspan ViewProfileLink floatLeftinArabic rtl"
                                >
                                  {translate("web.viewDetails", "View Details")}
                                  <i className="fa-solid fa-chevron-right justRotate red-fz-8-op-25"></i>
                                  <i className="fa-solid fa-chevron-right justRotate red-fz-8-op-50"></i>
                                  <i className="fa-solid fa-chevron-right justRotate red-fz-8-op-1"></i>
                                </Link>

                                <Link
                                  className="hoveronlink ViewProfileLink rtlabs text-end"
                                  style={{
                                    position: "absolute",
                                    bottom: 20,
                                    right: 10,
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let confirmText = window.confirm(
                                      translate("web.confirmMsg", "Confirm Msg")
                                    );
                                    if (confirmText) {
                                      dispatch(
                                        FetchDeleteHorseMarketthunk(item.id)
                                      );
                                    } else {
                                      return false;
                                    }
                                  }}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.05063 8.73418C4.20573 7.60763 5.00954 6 6.41772 6H17.5823C18.9905 6 19.7943 7.60763 18.9494 8.73418V8.73418C18.3331 9.55584 18 10.5552 18 11.5823V18C18 20.2091 16.2091 22 14 22H10C7.79086 22 6 20.2091 6 18V11.5823C6 10.5552 5.66688 9.55584 5.05063 8.73418V8.73418Z"
                                      stroke="#717171"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      d="M14 17L14 11"
                                      stroke="#717171"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M10 17L10 11"
                                      stroke="#717171"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M16 6L15.4558 4.36754C15.1836 3.55086 14.4193 3 13.5585 3H10.4415C9.58066 3 8.81638 3.55086 8.54415 4.36754L8 6"
                                      stroke="#717171"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                    />
                                  </svg>

                                  {
                                    // <i
                                    //   className="fa fa-trash"
                                    //   style={{
                                    //     color: "#777777",
                                    //     fontSize: 15,
                                    //     fontWeight: "bold",
                                    //   }}
                                    // ></i>
                                  }
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    <div
                      className="col-lg-12 col-md-12 col-sm-12 text-center"
                      style={{ marginTop: 20 }}
                    ></div>
                  </React.Fragment>
                )}
                {addNewHorse && (
                  <div className="col-lg-12 col-md-12 col-sm-6">
                    <div
                      className="row"
                      style={{
                        border: "1px solid #C89C66",
                        borderRadius: 5,
                        padding: 20,
                      }}
                    >
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        {
                          // <NetaqInput
                          //     type={"number"}
                          //     name={"registerationNumber"}
                          //     placeholder={translate(
                          //       "web.registerationNumber",
                          //       "Registeration Number"
                          //     )}
                          //     label={translate(
                          //       "web.registerationNumber",
                          //       "Registeration Number"
                          //     )}
                          //     onChange={(e) => handleNewHorseChange(e, "regNo")}
                          //     value={newHorseModel.regNo}
                          //   />
                        }
                        <label
                          className="capatalize_text"
                        //onClick={() => handleHorseMarketReset()}
                        >
                          {translate(
                            "web.registerationNumber",
                            "Registeration Number"
                          )}
                        </label>
                        <span className="requiredColor">*</span>
                        {
                          <Select
                            options={[
                              {
                                label: translate(
                                  "web.registerationNumber",
                                  "Registeration Number"
                                ),
                                value: "",
                              },
                              ...(!!ownerHorsesDropDownData &&
                                ownerHorsesDropDownData
                                  // .filter((f) => {
                                  //   //!!ownerHorseMarketData &&
                                  //   //ownerHorseMarketData.length > 0 &&
                                  //   //a.id != ownerHorseMarketData[i].horseId
                                  //   return ownerHorseMarketData.some((s) => {
                                  //     return f.id !== s.horseId;
                                  //   });
                                  // })
                                  .map((a) => ({
                                    label: a.registerNumber + " - " + a.name,
                                    value: a.registerNumber,
                                  }))),
                            ]}
                            value={newHorseModel.selectDrop}
                            onChange={(e) => handleNewHorseChange(e, "regNo")}
                            placeholder={translate(
                              "web.registerationNumber",
                              "Registeration Number"
                            )}
                            name={"registerationNumber"}
                            isDisabled={newHorseModel.id !== ""}
                            isSearchable
                            //isClearable
                            noOptionsMessage={() =>
                              translate("web.noOptions", "No Options")
                            }
                            autoFocus={newHorseModel.id === ""}
                          />
                        }
                        {
                          // <NetaqInput
                          //   htmlTags={<span className="requiredColor">*</span>}
                          //   type={"select"}
                          //   value={newHorseModel.regNo}
                          //   Options={
                          //     !!ownerHorsesDropDownData &&
                          //     ownerHorsesDropDownData
                          //       .filter((f) => {
                          //         return ownerHorseMarketData.some((s) => {
                          //           return f.id !== s.horseId;
                          //         });
                          //       })
                          //       .map((a) => ({
                          //         name: a.registerNumber + " - " + a.name,
                          //         value: a.registerNumber,
                          //       }))
                          //   }
                          //   name={"registerationNumber"}
                          //   placeholder={translate(
                          //     "web.registerationNumber",
                          //     "Registeration Number"
                          //   )}
                          //   label={translate(
                          //     "web.registerationNumber",
                          //     "Registeration Number"
                          //   )}
                          //   onChange={(e) => handleNewHorseChange(e, "regNo")}
                          //   disabled={newHorseModel.id !== ""}
                          // />
                        }
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          type={"text"}
                          name={"arabicName"}
                          placeholder={translate(
                            "web.arabicName",
                            "Arabic Name"
                          )}
                          label={translate("web.arabicName", "Arabic Name")}
                          disabled={true}
                          value={newHorseModel.nameAr}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          type={"text"}
                          name={"englishName"}
                          placeholder={translate(
                            "web.englishName",
                            "English Name"
                          )}
                          label={translate("web.englishName", "English Name")}
                          disabled={true}
                          value={newHorseModel.nameEn}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          type={"text"}
                          name={"address"}
                          placeholder={translate("web.address", "Address")}
                          label={translate("web.address", "Address")}
                          disabled={true}
                          value={newHorseModel.address}
                        />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          htmlTags={<span className="requiredColor">*</span>}
                          type={"select"}
                          value={newHorseModel.categoryDrop}
                          listSelect={[
                            {
                              text: translate("web.showHorse", "Show Horse"),
                              value: 1,
                            },
                            {
                              text: translate("web.flatRace", "Flat Race"),
                              value: 2,
                            },
                            {
                              text: translate(
                                "web.enduranceHorse",
                                "Endurance Horse"
                              ),
                              value: 3,
                            },
                          ]}
                          name={"category"}
                          placeholder={translate("web.category", "Category")}
                          label={translate("web.category", "Category")}
                          onChange={(e) => handleNewHorseChange(e, "category")}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          htmlTags={<span className="requiredColor">*</span>}
                          type={"select"}
                          value={newHorseModel.typeDrop}
                          listSelect={[
                            {
                              text: translate(
                                "web.horseMarket",
                                "Horse Market"
                              ),
                              value: "HorseMarket",
                            },
                            {
                              text: translate(
                                "web.horseCharity",
                                "Horse Charity"
                              ),
                              value: "HorseCharity",
                            },
                            {
                              text: translate(
                                "web.semenMarket",
                                "Semen Market"
                              ),
                              value: "SemenMarket",
                            },
                          ]}
                          name={"type"}
                          placeholder={translate("web.type", "type")}
                          label={translate("web.type", "type")}
                          onChange={(e) => handleNewHorseChange(e, "type")}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          htmlTags={<span className="requiredColor">*</span>}
                          type={"number"}
                          name={"horsePrice"}
                          placeholder={translate(
                            "web.priceInAED",
                            "Price In AED"
                          )}
                          label={translate("web.price", "Price")}
                          onChange={(e) => handleNewHorseChange(e, "price")}
                          value={newHorseModel.price}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          htmlTags={<span className="requiredColor">*</span>}
                          type={"select"}
                          value={newHorseModel.cityDrop}
                          listSelect={
                            !!citiesData &&
                            citiesData.map((a) => ({
                              text:
                                currentLanguage === "en" ? a.name : a.nameAr,
                              value: a.id,
                            }))
                          }
                          name={"city"}
                          placeholder={translate("web.emirate", "Emirate")}
                          label={translate("web.emirate", "Emirate")}
                          onChange={(e) => handleNewHorseChange(e, "city")}
                        />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          htmlTags={<span className="requiredColor">*</span>}
                          type={"text"}
                          name={"phone"}
                          placeholder={translate("web.phone", "Phone")}
                          label={translate("web.phone", "Phone")}
                          onChange={(e) => handleNewHorseChange(e, "phone")}
                          value={newHorseModel.phone}
                        // value={
                        //   newHorseModel.phone !== ""
                        //     ? newHorseModel.phone
                        //     : userProfile?.phone
                        // }
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          htmlTags={<span className="requiredColor">*</span>}
                          type={"email"}
                          name={"email"}
                          placeholder={translate("web.email", "Email")}
                          label={translate("web.email", "Email")}
                          onChange={(e) => handleNewHorseChange(e, "email")}
                          value={newHorseModel.email}
                        // value={
                        //   newHorseModel.email !== ""
                        //     ? newHorseModel.email
                        //     : userProfile?.email
                        // }
                        />
                        <span className={`text-danger ${emailNotValidMsg}`}>
                          {translate("web.emailNotValid", "Email not valid")}
                        </span>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                          //htmlTags={<span className="requiredColor">*</span>}
                          type={"text"}
                          name={"urlVideo"}
                          placeholder={translate("web.urlVideo", "URL Video")}
                          label={translate("web.urlVideo", "URL Video")}
                          onChange={(e) => handleNewHorseChange(e, "urlVideo")}
                          value={newHorseModel.urlVideo}
                        />
                        <span className={`text-danger ${urlVideoNotValidMsg}`}>
                          {translate("web.urlNotValid", "URL not valid")}
                        </span>
                      </div>

                      {newHorseModel.id !== "" && (
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <NetaqInput
                            name={"isSold"}
                            type={"checkbox"}
                            checked={newHorseModel.isSold}
                            label={translate("web.isSold", "isSold")}
                            onChange={(e) => handleNewHorseChange(e, "isSold")}
                          />
                        </div>
                      )}
                    </div>
                    <br />
                    <div
                      className="col-lg-12 col-md-12 col-sm-12"
                      style={{ padding: 0 }}
                    >
                      <FileUpload
                        multiple={true}
                        onFilesUploaded={setFiles}
                        emptyFiles={emptyFiles}
                        setEmptyFiles={setEmptyFiles}
                        urlImages={urlImages}
                        setUrlImages={setUrlImages}
                      />
                    </div>
                    <div className="submitButtonContainerleft">
                      {
                        // <ActionButton
                        //     type={"btn"}
                        //     label={"reset"}
                        //     onClick={() => handleHorseMarketReset()}
                        //     text={"reset"}
                        //     margin={"0"}
                        //   />
                      }
                      <ActionButton
                        type={"btn"}
                        label={translate("web.cancel", "Cancel")}
                        onClick={() => {
                          setAddNewHorse(false);
                          handleHorseMarketReset();
                          dispatch(
                            FetchOwnerHorseMarketthunk(
                              //"3a5f8ec0-c888-4b2b-4c0c-08dc12faba88"
                              localStorage.getItem("currentSubAccountId")
                            )
                          );
                        }}
                        text={translate("web.cancel", "Cancel")}
                        margin={"0"}
                      />
                      <ActionButton
                        type={"submit"}
                        label={translate("web.save", "Save")}
                        text={translate("web.save", "Save")}
                        margin={"0"}
                        onClick={handelNewHorse}
                        disabled={
                          newHorseModel.regNo !== "" &&
                            newHorseModel.horseId !== "" &&
                            newHorseModel.category !== "" &&
                            newHorseModel.type !== "" &&
                            newHorseModel.price !== "" &&
                            newHorseModel.city !== "" &&
                            newHorseModel.phone !== "" &&
                            newHorseModel.email !== "" &&
                            //newHorseModel.urlVideo !== "" &&
                            ((newHorseModel.id === "" && files.length > 0) ||
                              (newHorseModel.id !== "" &&
                                (files.length > 0 || urlImages.length > 0)))
                            ? false
                            : true
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {activeTab === "showEntry" && (
              <>
                <div className="col-lg-9 col-md-8 col-sm-12 row m0 justify-content-between  rtl">
                  <div className="mb-2">
                    <div className="form-group ownerProfileInput ">
                      <i className="fas fa-search fa-xl iconformshowentrycard"></i>
                      <input
                        type="text"
                        className="form-control height30hidden zindex0"
                        onChange={(e) => {
                          setSearchShow(e.target.value);
                        }}
                        placeholder={translate(
                          "web.searchShow",
                          "Search By Show Name"
                        )}
                      />
                    </div>
                  </div>
                  {/* </div>
                <div className="col-lg-9 col-md-8 col-sm-12 justRotate rtl "> */}
                  {!!showEntryData && showEntryData.length > 0
                    ? showEntryData
                      .filter((item) => {
                        if (searchShow === "") {
                          return item;
                        } else if (searchShow !== "") {
                          return item.showName
                            .toLowerCase()
                            .includes(searchShow.toLowerCase());
                        }
                      })
                      .map((item, i) => (
                        <div
                          className="col-lg-6 col-md-12 col-sm-12 my-1 show-entry-first-div d-flex"
                          // style={{ background: '#FCFAF7' }}
                          key={i}
                        >
                          <div
                            className="horseCardContainer row m0"
                            style={{ display: "flex" }}
                          >
                            <div className="col-lg-4 col-md-8 col-sm-12 horse-market-card-div horseMarketImageContainer  showsImageContainer d-flex ">
                              <img
                                src={item.logo}
                                alt="logo"
                                style={{ margin: "auto" }}
                              />
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-12  p0">
                              <div className="col-md-12 col-sm-12 titleShowSchedule p0">
                                <div className="col-md-12 col-sm-12 p0">
                                  <span className="horseMarketCardLabeltitle">
                                    {item.showName}
                                  </span>
                                </div>
                                {item.showParticipantRequestStatus ===
                                  "Returned" && (
                                    <div className="col-md-1 col-sm-12 textalignend">
                                      <i class="fa-solid fa-bell colorred"></i>
                                    </div>
                                  )}
                              </div>

                              <div className="row rtl m0">
                                <div className="col-md-12 ">
                                  <span className="horseCardlabelContent ">
                                    {item.cityName}
                                  </span>
                                  <div className="end-date-showlist mt-1">
                                    <div className="col-md-12 px-2 start-end-date-display-ownerprofile">
                                      <div>
                                        <i
                                          className="fa fa-trophy "
                                          style={{ marginRight: 8 }}
                                        ></i>
                                        {item.showRank}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* shows Start Date */}
                                <div className=" start-date-showlist mt-1">
                                  <div className="col-md-12 px-2 background-color-orange start-end-date-display-ownerprofile">
                                    <div>
                                      <i className="fa fa-calendar "></i>
                                      {translate(
                                        "web.startDate",
                                        "Start Date"
                                      )}
                                      :
                                    </div>
                                    <div>
                                      {item.startDate &&
                                        formatDate(item.startDate)}
                                    </div>
                                    {
                                      // <div>
                                      //   <span>{item.startTime}</span>
                                      // </div>
                                    }
                                  </div>
                                </div>
                                <div className=" end-date-showlist mt-1">
                                  <div className="col-md-12 px-2 start-end-date-display-ownerprofile">
                                    <div>
                                      <i className="fa fa-calendar"></i>
                                      {translate("web.endDate", "End Date")}:
                                    </div>
                                    <div>
                                      {item.endDate &&
                                        formatDate(item.endDate)}
                                    </div>
                                  </div>
                                </div>
                                {/* Show register dates */}
                                <div className=" start-date-showlist mt-1">
                                  <div className="col-md-12 px-2 start-end-date-display-ownerprofile background-color-orange ">
                                    <div>
                                      <i className="fa fa-calendar "></i>
                                      {translate(
                                        "web.registerStartDate",
                                        "Register Start Date"
                                      )}
                                      :
                                    </div>
                                    <div>
                                      {formatDate(item.registerStartDate)}
                                    </div>
                                  </div>
                                </div>

                                <div className=" end-date-showlist mt-1">
                                  <div className="col-md-12 px-2 start-end-date-display-ownerprofile">
                                    <div>
                                      <i className="fa fa-calendar"></i>
                                      {translate(
                                        "web.registerEndDate",
                                        "Register End Date"
                                      )}
                                      :
                                    </div>
                                    <div>
                                      {item.registerEndDate &&
                                        formatDate(item.registerEndDate)}
                                    </div>
                                  </div>
                                </div>

                                {item.registerStartDateInter &&
                                  formatDate(item.registerStartDateInter) !==
                                  formatDate(item.registerStartDate) &&
                                  formatDate(item.registerEndDateInter) !==
                                  formatDate(item.registerEndDate) && (
                                    <React.Fragment>
                                      <div className="start-date-showlist mt-1">
                                        <div className="col-md-12 px-2 background-color-orange start-end-date-display-ownerprofile">
                                          <div>
                                            <i className="fa fa-calendar "></i>
                                            {translate(
                                              "web.registerStartDateInter",
                                              "Register Start Date Inter"
                                            )}
                                            :
                                          </div>
                                          <div>
                                            {item.registerStartDateInter &&
                                              formatDate(
                                                item.registerStartDateInter
                                              )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="end-date-showlist mt-1">
                                        <div className="col-md-12 px-2 start-end-date-display-ownerprofile">
                                          <div>
                                            <i className="fa fa-calendar"></i>
                                            {translate(
                                              "web.registerEndDateInter",
                                              "Register End Date Inter"
                                            )}
                                            :
                                          </div>
                                          <div>
                                            {item.registerEndDateInter &&
                                              formatDate(
                                                item.registerEndDateInter
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )}
                                <div className="row m0 rtl">
                                  <div className="col-sm-8 cardValueColor ">
                                    {translate(
                                      "web.maxNoPerOwner",
                                      "Max no of horses to register per owner"
                                    )}
                                    :
                                  </div>
                                  <div className="col-sm-4 cardKeyColor  ">
                                    {item.maxHorseNumberPerOwner > 0
                                      ? item.maxHorseNumberPerOwner
                                      : translate(
                                        "web.unlimited",
                                        "Unlimited"
                                      )}
                                  </div>
                                </div>
                                <span className="horseCardLabeltitle col-sm-12 text-end">
                                  {item.entryFees}{" "}
                                  {currentLanguage === "en" ? "AED" : "د.إ"}
                                </span>
                              </div>
                            </div>
                            <div className="row justify-content-end m0 align-items-center ">
                              {!(
                                item.showParticipantRequestStatus ===
                                "Completed" &&
                                item.IsShowRegisterStarted == true
                              ) && (
                                  <Link
                                    className={`btn button-saveasdraft col-sm-5  ${CompareDate(
                                      item.registerStartDate,
                                      item.endDate
                                    )
                                      ? "newbtn"
                                      : "btn-danger"
                                      }`}
                                    disabled={
                                      CompareDate(
                                        item.registerStartDate,
                                        item.endDate
                                      )
                                        ? false
                                        : true
                                    }
                                    onClick={(e) => {
                                      !CompareDate(
                                        item.registerStartDate,
                                        item.endDate
                                      ) && e.preventDefault();
                                    }}
                                    to={
                                      //`/showentrycard?entryFees=${item.entryFees}&maxHorses=${item.maxHorseNumberPerOwner}&showName=${item.showName}&showId=${item.id}&showParticipantRequestStatus=${item.showParticipantRequestStatus}`
                                      `/showentrycard?entryFees=${item.entryFees
                                      }&maxHorses=${parseInt(item.maxHorseNumberPerOwner)
                                      // parseInt(item.maxHorseNumberPerOwner) -
                                      // parseInt(item.completedRequestsCount)
                                      }&showNameAr=${item.showNameAr
                                      }&showNameEn=${item.showNameEn}&showId=${item.id
                                      }&showParticipantRequestStatus=${item.showParticipantRequestStatus
                                      }&completedRequestsCount=${item.completedRequestsCount
                                      }&registerStartDate=${item.registerStartDate
                                      }&registerEndDate=${item.registerEndDate
                                      }&registerStartDateInter=${item.registerStartDateInter
                                      }&registerEndDateInter=${item.registerEndDateInter
                                      }&showStartDate=${item.startDate}`
                                      // :  `/showentryconflict?maxHorses=${item.maxHorseNumberPerOwner}&showNameAr=${item.showNameAr}&showNameEn=${item.showNameEn}&showId=${item.id}`
                                    }
                                    style={
                                      CompareDate(
                                        item.registerStartDate,
                                        item.endDate
                                      )
                                        ? { opacity: 1, cursor: "pointer" }
                                        : {
                                          opacity: 0.5,
                                          cursor: "not-allowed",
                                        }
                                    }
                                  >
                                    {
                                      item.showParticipantRequestStatus ===
                                        "New" &&
                                        new Date() <
                                        new Date(item.registerEndDate)
                                        ? translate(
                                          "web.participateNow",
                                          "Participate Now"
                                        )
                                        : translate("web.continue", "Continue")
                                      // : translate(
                                      //   "web.addConflict",
                                      //   "Add Conflict"
                                      // )
                                    }
                                  </Link>
                                )}
                              {item.isShowJudgesAdded &&
                                item.showParticipantRequestStatus ===
                                "Completed" &&
                                CompareDate(
                                  item.registerStartDate,
                                  item.startDate
                                ) && (
                                  <Link
                                    className={`btn button-saveasdraft col-sm-5  ${CompareDate(
                                      item.registerStartDate,
                                      item.startDate
                                    )
                                      ? "newbtn"
                                      : "btn-danger"
                                      }`}
                                    disabled={
                                      CompareDate(
                                        item.registerStartDate,
                                        item.startDate
                                      )
                                        ? false
                                        : true
                                    }
                                    onClick={(e) => {
                                      !CompareDate(
                                        item.registerStartDate,
                                        item.startDate
                                      ) && e.preventDefault();
                                    }}
                                    to={`/showentryconflict?maxHorses=${item.maxHorseNumberPerOwner}&showNameAr=${item.showNameAr}&showNameEn=${item.showNameEn}&showId=${item.id}`}
                                    style={
                                      CompareDate(
                                        item.registerStartDate,
                                        item.startDate
                                      )
                                        ? { opacity: 1, cursor: "pointer" }
                                        : {
                                          opacity: 0.5,
                                          cursor: "not-allowed",
                                        }
                                    }
                                  >
                                    {translate(
                                      "web.addConflict",
                                      "Add Conflict"
                                    )}
                                  </Link>
                                )}
                              {item.showParticipantRequestStatus ===
                                "Completed" &&
                                item.IsShowRegisterStarted == true &&
                                (item.maxHorseNumberPerOwner >
                                  item.completedRequestsCount ||
                                  item.maxHorseNumberPerOwner == 0) && (
                                  <Link
                                    className={`btn button-saveasdraft col-sm-6 ${CompareDate(
                                      item.registerStartDate,
                                      item.endDate
                                    )
                                      ? "newbtn"
                                      : "btn-danger"
                                      }`}
                                    style={
                                      CompareDate(
                                        item.registerStartDate,
                                        item.endDate
                                      )
                                        ? { opacity: 1, cursor: "pointer" }
                                        : {
                                          opacity: 0.5,
                                          cursor: "not-allowed",
                                        }
                                    }
                                    disabled={
                                      CompareDate(
                                        item.registerStartDate,
                                        item.endDate
                                      )
                                        ? false
                                        : true
                                    }
                                    onClick={(event) => {
                                      if (
                                        !CompareDate(
                                          item.registerStartDate,
                                          item.endDate
                                        )
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    to={`/showentrycard?entryFees=${item.entryFees
                                      }&maxHorses=${parseInt(
                                        item.maxHorseNumberPerOwner
                                      )}&showNameAr=${item.showNameAr
                                      }&showNameEn=${item.showNameEn}&showId=${item.id
                                      }&showParticipantRequestStatus=${item.showParticipantRequestStatus
                                      }&registerStartDate=${item.registerStartDate
                                      }&registerEndDate=${item.registerEndDate
                                      }&registerStartDateInter=${item.registerStartDateInter
                                      }&registerEndDateInter=${item.registerEndDateInter
                                      }&showStartDate=${item.startDate}`}
                                  >
                                    {new Date() >
                                      new Date(item.registerEndDate) ? (
                                      <>
                                        {translate(
                                          "web.continue",
                                          "Continue"
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {translate(
                                          "web.addAdditionalHorse",
                                          "Add Additional Horse"
                                        )}
                                      </>
                                    )}
                                  </Link>
                                )}
                              <div className="col-md-6 col-sm-12 text-end ">
                                <Link
                                  className="hoveronlink   rtl"
                                  to={`/showentrydetails?showId=${item.id}`}
                                >
                                  {translate(
                                    "web.viewDetails",
                                    "View Details"
                                  )}
                                  <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"></i>
                                  <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"></i>
                                  <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : 0 + " " + translate("web.resultsfound", "Results Found")}
                  {totalCountShowEntry > pageShowEntry * takeShowEntry && (
                    <div className="row justify-content-center rtl mt-2">
                      <div className="col-lg-6 col-md-6 col-sm-6 text-center">
                        <ActionButton
                          type={"loadmore"}
                          label={translate("web.loadMore", "Load More")}
                          onClick={() => {
                            dispatch(incrementPageShowEntry());
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {activeTab === "auctionEntry" && (
              <div className="col-lg-9 col-md-8 col-sm-12 row m0 rtl">
                <div className="col-lg-12 col-sm-12">
                  <div className="form-group ownerProfileInput">
                    <i className="fas fa-search fa-xl iconformshowentrycard"></i>
                    <input
                      type="text"
                      className="form-control height30hidden zindex0"
                      onChange={(e) => {
                        setSearchAuction(e.target.value);
                      }}
                      placeholder={translate(
                        "web.searchAuction",
                        "Search By Auction Name"
                      )}
                    />
                  </div>

                  {!!auctionEntryData && auctionEntryData.length > 0 ? (
                    auctionEntryData
                      .filter((item) => {
                        if (searchAuction === "") {
                          return item;
                        } else if (searchAuction !== "") {
                          return (
                            item.nameAr
                              .toLowerCase()
                              .includes(searchAuction.toLowerCase()) ||
                            item.nameEn
                              .toLowerCase()
                              .includes(searchAuction.toLowerCase())
                          );
                        }
                      })
                      .map((item, i) => (
                        <div
                          className="col-lg-6 col-md-6 col-sm-12  show-entry-first-div"
                          key={i}
                        >
                          <div
                            className="horseCardContainer row mx-1"
                            style={{ display: "flex" }}
                          >
                            <div className="col-lg-4 col-md-12 col-sm-12 horse-market-card-div horseMarketImageContainer  showsImageContainer ">
                              <img src={item.attachment} alt="logo" />
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-12 p0">
                              <div className="col-md-12 col-sm-12 titleShowSchedule p0">
                                <div className="col-md-12 col-sm-12 p0">
                                  <span className="horseMarketCardLabeltitle">
                                    {currentLanguage === "en"
                                      ? item.nameEn
                                      : item.nameAr}
                                  </span>
                                </div>
                              </div>

                              <div className="row justRotate ">
                                <div className="col-md-12">
                                  <div className="justRotate start-date-showlist ">
                                    <div className="col-md-12 background-color-orange px-2 start-end-date-display-ownerprofile">
                                      <div>
                                        <i className="fa fa-calendar "></i>
                                        {translate(
                                          "web.startDate",
                                          "Start Date"
                                        )}
                                        :
                                      </div>
                                      <div>
                                        {item.startDate &&
                                          formatDate(item.startDate)}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="justRotate end-date-showlist">
                                    <div className="col-md-12 start-end-date-display-ownerprofile px-2">
                                      <div>
                                        <i className="fa fa-calendar"></i>
                                        {translate("web.endDate", "End Date")}:
                                      </div>
                                      <div>
                                        {item.endDate &&
                                          formatDate(item.endDate)}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="justRotate start-date-showlist ">
                                    <div className="col-md-12 background-color-orange start-end-date-display-ownerprofile px-2">
                                      <div>
                                        <i className="fa fa-calendar "></i>
                                        {translate(
                                          "web.participationStartDate",
                                          "Participation Start Date"
                                        )}
                                        :
                                      </div>
                                      <div>
                                        {item.participationStartDate &&
                                          formatDate(
                                            item.participationStartDate
                                          )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="justRotate end-date-showlist">
                                    <div className="col-md-12 start-end-date-display-ownerprofile px-2">
                                      <div>
                                        <i className="fa fa-calendar"></i>
                                        {translate(
                                          "web.participationEndDate",
                                          "Participation End Date"
                                        )}
                                        :
                                      </div>
                                      <div>
                                        {item.participationEndDate &&
                                          formatDate(item.participationEndDate)}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-10 cardValueColor justRotate mt-11wahoconference">
                                      {translate(
                                        "web.maxNoPerOwner",
                                        "Max no of horses to register per owner"
                                      )}
                                      :
                                    </div>
                                    <div className="col-sm-2 cardKeyColor justRotate mt-11wahoconference">
                                      {item.maxNumberPerOwner}
                                    </div>
                                  </div>
                                  <span className="horseCardLabeltitle justRotate mt-11wahoconference textalignend">
                                    {item.horseFees}{" "}
                                    {currentLanguage === "en" ? "AED" : "د.إ"}
                                  </span>
                                </div>
                              </div>

                              <div className="textalignend">
                                <Link
                                  className={`btn button-saveasdraft ${CompareDate(
                                    item.participationStartDate,
                                    item.participationEndDate
                                  )
                                    ? "newbtn"
                                    : "btn-danger"
                                    }`}
                                  disabled={
                                    CompareDate(
                                      item.participationStartDate,
                                      item.participationEndDate
                                    )
                                      ? false
                                      : true
                                  }
                                  style={
                                    CompareDate(
                                      item.participationStartDate,
                                      item.participationEndDate
                                    )
                                      ? { opacity: 1, cursor: "pointer" }
                                      : { opacity: 0.5, cursor: "not-allowed" }
                                  }
                                  onClick={(event) => {
                                    if (
                                      !CompareDate(
                                        item.participationStartDate,
                                        item.participationEndDate
                                      )
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  to={`/auctionentrycard?horseFees=${item.horseFees
                                    }&maxHorses=${parseInt(
                                      item.maxNumberPerOwner
                                    )}&auctionNameAr=${item.nameAr
                                    }&auctionNameEn=${item.nameEn}&auctionId=${item.id
                                    }`}
                                >
                                  {item.checkRequests
                                    ? translate("web.continue", "Continue")
                                    : translate(
                                      "web.participateNow",
                                      "Participate Now"
                                    )}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div>
                      {0 + " " + translate("web.resultsfound", "Results Found")}
                    </div>
                  )}
                </div>
              </div>
              // <div className="col-lg-9 col-md-9 col-sm-12 justRotate">
              //   <ComingSoon />
              // </div>
            )}
            {activeTab === "e_services" && (
              <div className="col-lg-9 col-md-8 col-sm-12  rtl">
                <div className="form-group ownerProfileInput w100max450">
                  <i className="fas fa-search fa-xl iconformshowentrycard"></i>
                  <input
                    type="text"
                    className="form-control height30hidden zindex0"
                    onChange={(e) => {
                      setSearchServices(e.target.value);
                    }}
                    placeholder={translate(
                      "web.searchByRefNo",
                      "Search By Ref.No"
                    )}
                  />
                </div>
                <table className="table table-striped table-bordered rtl horseResultTable">
                  <thead className="theadHorseSelect">
                    <tr>
                      <th className="text-center">
                        {translate("web.refNo", "Ref.No")}
                      </th>
                      <th className="text-center">
                        {translate("web.serviceType", "Service Type")}
                      </th>
                      <th className="text-center">
                        {translate("web.horse", "Horse")}
                      </th>
                      {
                        //   <th className="text-center">
                        //   {translate("web.owner", "Owner")}
                        // </th>
                      }
                      <th className="text-center">
                        {translate("web.createdDate", "Created Date")}
                      </th>
                      <th className="text-center">
                        {translate("web.submissionDate", "Submission Date")}
                      </th>
                      <th className="text-center">
                        {translate("web.status", "Status")}
                      </th>
                      <th className="text-center">
                        {translate("web.paymentStatus", "Payment Status")}
                      </th>
                      <th className="text-center">
                        {translate("web.action", "Action")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {!servicesRequestsLoading &&
                      getAllServicesRequestsData.length > 0 &&
                      getAllServicesRequestsData
                        //.filter((item) => item.isCreated)
                        .filter((item) => {
                          if (searchServices === "") {
                            return item;
                            //  (
                            //   item.ownerShipTransfer.ownerId ==
                            //     localStorage.getItem("currentSubAccountId") ||
                            //   (item.ownerShipTransfer.ownerId !=
                            //     localStorage.getItem("currentSubAccountId") &&
                            //     (subOwnerProfilesByIdData.length == 0 ||
                            //       (subOwnerProfilesByIdData.length > 0 &&
                            //         subOwnerProfilesByIdData.some(
                            //           (q) =>
                            //             q.value !=
                            //             item.ownerShipTransfer.ownerId
                            //         ))))
                            // );
                          } else if (searchServices !== "") {
                            return item.referenceNumber
                              .toLowerCase()
                              .includes(searchServices.toLowerCase());
                          }
                        })
                        .map((item) => (
                          <tr>
                            <td>{item.referenceNumber}</td>
                            <td>{item.serviceDefinitionName}</td>
                            <td>{item.horseName}</td>
                            {
                              //  <td style={{ maxWidth: 150 }}>
                              //   {item.ownerId}
                              //   {" - "}
                              //   {item.ownerName}
                              // </td>
                            }
                            <td>
                              {item.createdDate && formatDate(item.createdDate)}
                            </td>
                            <td>{item.date && formatDate(item.date)}</td>
                            <td>{item.status}</td>
                            <td>
                              {translate(
                                `web.${item.paymentStatus.toLowerCase()}`,
                                item.paymentStatus
                              )}
                            </td>
                            <td>
                              {(item.eventName === "Draft" ||
                                item.eventName === "BuyerRejected") &&
                                (item.ownerShipTransfer.ownerId ==
                                  localStorage.getItem("EAHSUserID") ||
                                  item.ownerShipTransfer.createdBy ==
                                  localStorage.getItem("EAHSUserID")) && (
                                  <div className="row rtl">
                                    <Link
                                      to={
                                        item.serviceDefinitionId ===
                                        "53ab6bf3-596b-47ec-8d5d-65bf44d2e5c5" &&
                                        `/requestHorseOwnershipTransferSeller?serviceId=${item.serviceDefinitionId}&id=${item.id}&action=edit`
                                      }
                                    >
                                      <i
                                        class="fas fa-edit"
                                        title={translate("web.edit", "Edit")}
                                      ></i>
                                    </Link>
                                    <Link
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setOpenPopup((o) => !o);
                                        setServiceRequestId(item.id);
                                      }}
                                    >
                                      <i
                                        className="fa-solid fa-trash"
                                        title={translate(
                                          "web.delete",
                                          "Delete"
                                        )}
                                      ></i>
                                    </Link>
                                  </div>
                                )}
                              {item.eventName !== "Draft" && (
                                //&& item.eventName !== "BuyerRejected"
                                <div className="row rtl">
                                  <Link
                                    to={
                                      item.serviceDefinitionId ===
                                      "53ab6bf3-596b-47ec-8d5d-65bf44d2e5c5" &&
                                      `/requestHorseOwnershipTransferSeller?serviceId=${item.serviceDefinitionId}&id=${item.id}&action=view`
                                    }
                                    className="hoveronspan ViewProfileLink floatLeftinArabic rtl fontWeight"
                                  >
                                    <i
                                      className="fa-solid fa-eye"
                                      title={translate("web.view", "View")}
                                    ></i>
                                  </Link>
                                </div>
                              )}
                              {(item.eventName === "WatingBuyerApproval" ||
                                item.eventName == "SentBack") &&
                                item.ownerShipTransfer.newOwnerId ==
                                localStorage.getItem(
                                  "currentSubAccountId"
                                ) && (
                                  <div className="row rtl">
                                    <Link
                                      to={
                                        item.serviceDefinitionId ===
                                        "53ab6bf3-596b-47ec-8d5d-65bf44d2e5c5" &&
                                        `/requestHorseOwnershipTransferSeller?serviceId=${item.serviceDefinitionId}&id=${item.id}&action=approve`
                                      }
                                      className="hoveronspan ViewProfileLink floatLeftinArabic rtl fontWeight"
                                    >
                                      <i
                                        class="fa fa-check"
                                        title={translate(
                                          "web.approve",
                                          "Approve"
                                        )}
                                      ></i>
                                    </Link>
                                  </div>
                                )}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                {
                  //pop up
                }
                <Popup
                  modal
                  lockScroll
                  open={openPopup}
                  closeOnDocumentClick={false}
                  closeOnEscape
                  style={{ background: "red" }}
                  contentStyle={{ width: "90%", maxWidth: 400 }}
                >
                  {(close) => (
                    <React.Fragment>
                      <div className="">
                        {translate(
                          "web.confirmMsgDeleteServiceRequest",
                          "are you sure to delete service request?"
                        )}
                      </div>
                      <div className="divHorseSelect row rtl ">
                        <div
                          className="bidSubmit col-lg-4 col-sm-6 text-center "
                          style={{ padding: "5px 10px" }}
                          onClick={closeModal}
                        >
                          {translate("web.close", "Close")}
                        </div>
                        <div className=" col-lg-5 col-sm-6">
                          <button
                            type="button"
                            className="btn btn-danger btn-lg"
                            onClick={() => {
                              setOpenPopup((o) => !o);
                              handelDeleteServiceRequest(serviceRequestId);
                            }}
                          >
                            <i className="fa-solid fa-trash"></i>
                            {translate("web.delete", "Delete")}
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </Popup>
              </div>
            )}
            {activeTab === "paymentHistory" && (
              <div className="col-lg-9 col-md-8 col-sm-12">
                <PaymentHistory />
              </div>
            )}
            {activeTab === "participateHistory" && (
              <div className="col-lg-9 col-md-8 col-sm-12">
                <ParticipateHistory />
              </div>
            )}
            {activeTab === "organizerRequests" && (
              <div className="col-lg-9 col-md-8 col-sm-12">
                <OrginizingRequests />
              </div>
            )}
            {activeTab === "upgradeProfile" &&
              userProfile.accountType == "BasicAccount" && (
                <div className="col-lg-9 col-md-8 col-sm-12">
                  <UpgradeProfile
                    toast={toast}
                    reloadProfile={loadDataFromLocal}
                    setActiveTab={setActiveTab}
                  />
                </div>
              )}
            {activeTab === "owenerPermisions" &&
              userProfile.accountType == "Agent" && (
                <div className="col-lg-9 col-md-8 col-sm-12">
                  <OwnerPermisions
                    toast={toast}
                    reloadProfile={loadDataFromLocal}
                    setActiveTab={setActiveTab}
                  />
                </div>
              )}
          </div>
        </React.Fragment>
      }
    </div>
  );
}
